import React from 'react'
import { Helmet } from 'react-helmet'
import translate from './HOC/translate';

const projectName = "ZAMISTOM.KIEV.UA";
// const projectTitle = "zamistom.kiev.ua";
// const projectUrl = "zamistom.kiev.ua";

function MetaHead(props) {
  return (
    <Helmet>
      <title>{props.strings.title}{projectName}</title>
      <meta name="description" content="Найти где отдохнуть за городом на ZAMISTOM.KIEV.UA. Базы отдыха, отели, коттеджи и виллы под Киевом отзывы, цены!" />
      {/* <meta content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" name="SKYPE_TOOLBAR" />
      <meta name="description" content={props.strings.metaDesc1 + projectName + '. ' + props.strings.metaDesc2} />
      <meta name="robots" content="noyaca" /> 
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={projectTitle} />
      <meta property="og:title" content={props.strings.metaPropOgTitle+projectTitle} />
      <meta property="og:description"content={props.strings.metaPropOgDesc} />
      <meta property="og:url" content={`https://${projectUrl}`} />
      <meta property="og:locale" content={props.strings.metaPropOgLocale} />
      <meta property="og:image" content={`https://${projectUrl}/images/og_zamistom.png`} />
      <meta property="og:image:width" content="720" />
      <meta property="og:image:height" content="152" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.strings.metaTwitterTitle + projectName +'!'} />
      <meta name="twitter:description" content={props.strings.metaTwitterDesc} />
      <meta name="twitter:image:src" content={`https://${projectUrl}/images/og_zamistom.png`} />
      <meta name="twitter:url" content={`https://${projectUrl}`} />
      <meta name="twitter:domain" content={`${projectTitle}`} />
      <meta name="twitter:site" content="@zamistom_ua" />
      <meta name="twitter:creator" content="@zamistom_ua" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      <noscript id="jss-insertion-point" /> */}
      {/* https://elims.org.ua/blog/apple-touch-icon-png-i-apple-touch-icon-precomposed-png-ikonki-i-404-ya-oshibka/
      https://stanislavromanov.ru/blog/apple-touch-icon
      <link href="/apple-touch-icon-120x120-precomposed.png" rel="apple-touch-icon-precomposed" sizes="120x120" />
      <link href="/apple-touch-icon-120x120.png" rel="apple-touch-icon" sizes="120x120" />
      <link href="/apple-touch-icon-precomposed.png" rel="apple-touch-icon-precomposed" />
      <link href="/apple-touch-icon.png" rel="apple-touch-icon" />
      <link href="/apple-touch-icon-76x76-precomposed.png" rel="apple-touch-icon-precomposed" sizes="76x76" />
      <link href="/apple-touch-icon-152x152-precomposed.png" rel="apple-touch-icon-precomposed" sizes="152x152" /> */}
      <noscript id="jss-insertion-point" />
  </Helmet>
  )
}

export default translate('MetaHead')(MetaHead);

