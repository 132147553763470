import React from "react";
import PropTypes from "prop-types";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../_jss/HotelPage/breadCrumbSection";
import { ServicesDrawer, ReviewsDrawer } from "./";

const useStyles = makeStyles(styles);

export const TabsSection = props => {
  const [openServiceDrawer, setOpenServiceDrawer] = React.useState(false);
  const [openReviewsDrawer, setOpenReviewsDrawer] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };
  const clickTabServiceDrawer = open => () => {
    setOpenServiceDrawer(open);
  };
  const clickTabOpenReviewsDrawer = open => () => {
    setOpenReviewsDrawer(open);
  };
  const { hotel, strings, currentLanguage } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.menuHotelPage}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
      >
        <Tab label={strings.tabs.description} />
        <ServicesDrawer
          openServiceDrawer={openServiceDrawer}
          clickTabServiceDrawer={clickTabServiceDrawer}
          servicesName={strings.tabs.services}
          services={hotel.services}
          hotelname={hotel.hotelname}
          currentLanguage={currentLanguage}
        />
        <Tab href="#typeOfAccommod" label={strings.tabs.placement} />
        <ReviewsDrawer
          openReviewsDrawer={openReviewsDrawer}
          clickTabOpenReviewsDrawer={clickTabOpenReviewsDrawer}
          reviewsName={strings.tabs.reviews}
          reviews={hotel.reviews}
        />
      </Tabs>
    </Paper>
  );
};

TabsSection.propTypes = {
  hotel: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired
};
