import React from "react";
import PropTypes from "prop-types";
import Rating from "material-ui-rating";
import { Link } from "react-router-dom";
import { CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/HotelList/cardHeaderHotelName";

const useStyles = makeStyles(styles);

export const CardHeaderHotelName = props => {
  const { hotelname, rating, currentLanguage, hotel } = props;
  const classes = useStyles();
  const { iconButton, icon } = classes;
  const classesRate = { iconButton, icon };
  return (
    <Link
      to={{
        pathname:
          currentLanguage !== "uk"
            ? `${currentLanguage}/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
            : `ua/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
      }}
      target="_blank"
      className={classes.link}
    >
      <CardHeader
        classes={{
          title: classes.title
        }}
        avatar={<div className={classes.avatarCardHeader}>{rating}</div>}
        title={hotelname[currentLanguage]}
        subheader={
          <Rating readOnly classes={classesRate} value={rating} max={5} />
        }
      />
    </Link>
  );
};

CardHeaderHotelName.propTypes = {
  hotelname: PropTypes.object.isRequired,
  rating: PropTypes.number,
  currentLanguage: PropTypes.string.isRequired,
  hotel: PropTypes.object.isRequired
};
