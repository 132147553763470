import { iconButton, icon } from "../General/ratingStyle";

const cardContentStyle = theme => ({
  title: {
    fontSize: "0.8rem",
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      fontSize: "1rem"
    },
    color: "rgba(0, 0, 0, 0.87)"
  },
  avatarRoot: {
    [theme.breakpoints.down(400)]: {
      marginRight: theme.spacing(1)
    }
  },
  cardHeaderRoot: {
    [theme.breakpoints.down(400)]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1)
  },
  avatarCardHeader: {
    background: "#039be5",
    fontSize: "0.8rem",
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      fontSize: "1rem"
    },
    padding: "0 5px",
    color: "white",
    borderRadius: 2
  },
  iconButton,
  icon
});

export default cardContentStyle;
