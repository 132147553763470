import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CardActions, Button, IconButton } from "@material-ui/core";
import { Favorite } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/HotelList/hotelListStyle";

const useStyles = makeStyles(styles);

export const CardActionView = props => {
  const {
    strings,
    hotel,
    currentLanguage,
    handleExpandClick,
    id,
    expanded
  } = props;
  const classes = useStyles();
  return (
    <CardActions>
      <Button
        color="primary"
        className={classes.button}
        component={Link}
        to={{
          pathname:
            currentLanguage !== "uk"
              ? `${currentLanguage}/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
              : `ua/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
        }}
        target="_blank"
      >
        {strings.buttonDetailsText}
      </Button>
      <Button
        color="primary"
        key={hotel._id}
        onClick={() => handleExpandClick(id)}
        aria-expanded={expanded[id]}
        aria-label={strings.buttonReviewText}
      >
        {strings.buttonReviewText}
      </Button>
      <IconButton
        aria-label="Add to favorites"
        className={classes.favoriteIcon}
      >
        <Favorite />
      </IconButton>
    </CardActions>
  );
};

CardActionView.propTypes = {
  strings: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  expanded: PropTypes.object.isRequired
};
