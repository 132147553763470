export const initialStateFilters = {
  filters: [
    {
      name: "filterAccommod",
      items: [
        { name: "Cottage", selected: false, isFilter: true },
        { name: "Bungalow", selected: false, isFilter: false },
        { name: "Main Building", selected: false, isFilter: true },
        { name: "Villa", selected: false, isFilter: true },
        { name: "Tent", selected: false, isFilter: true }
      ]
    },
    {
      name: "Activities",
      items: [
        { name: "Beach", selected: false, isFilter: true },
        { name: "Boat/catamaran rental", selected: false, isFilter: true },
        { name: "GyroScooter rental", selected: false, isFilter: false },
        { name: "Tennis court", selected: false, isFilter: true },
        { name: "Kids' club", selected: false, isFilter: true },
        { name: "Playground", selected: false, isFilter: false },
        { name: "Horseback riding", selected: false, isFilter: true },
        { name: "Cycling routes", selected: false, isFilter: false },
        { name: "Bicycle rental", selected: false, isFilter: true },
        { name: "Darts", selected: false, isFilter: false },
        { name: "Ping-pong", selected: false, isFilter: false },
        { name: "Pool table", selected: false, isFilter: false },
        { name: "Fishing", selected: false, isFilter: true },
        { name: "Zoo", selected: false, isFilter: true },
        { name: "Paintball", selected: false, isFilter: false },
        { name: "Rope park", selected: false, isFilter: false },
        { name: "Trampoline", selected: false, isFilter: false }
      ]
    },
    {
      name: "Pool & SPA",
      items: [
        { name: "Solarium", selected: false, isFilter: false },
        { name: "Massage", selected: false, isFilter: false },
        { name: "SPA area", selected: false, isFilter: true },
        { name: "Beauty parlor", selected: false, isFilter: false },
        { name: "Bath: russian/finnish", selected: false, isFilter: false },
        { name: "Sauna", selected: false, isFilter: true },
        { name: "Pool", selected: false, isFilter: true },
        { name: "Indoor pool", selected: false, isFilter: false },
        { name: "Children's pool", selected: false, isFilter: false },
        { name: "Heated swimming pool", selected: false, isFilter: false }
      ]
    },
    {
      name: "Territory",
      items: [
        { name: "Forest", selected: false, isFilter: true },
        { name: "Garden", selected: false, isFilter: false },
        { name: "Terrace", selected: false, isFilter: false },
        { name: "BBQ facilities", selected: false, isFilter: false },
        { name: "Skewers for rent", selected: false, isFilter: false },
        { name: "Altanka rental", selected: false, isFilter: false },
        { name: "Altanka for free", selected: false, isFilter: false },
        { name: "Benches", selected: false, isFilter: false },
        { name: "Lawn", selected: false, isFilter: false },
        { name: "Apiary", selected: false, isFilter: false },
        { name: "Food shop", selected: false, isFilter: false },
        { name: "Gift shop", selected: false, isFilter: false },
        { name: "Pond", selected: false, isFilter: false },
        { name: "Lake", selected: false, isFilter: true },
        { name: "River", selected: false, isFilter: true }
      ]
    },
    {
      name: "Food & Drink",
      items: [
        { name: "Restaurant", selected: false, isFilter: true },
        { name: "Cafe", selected: false, isFilter: false },
        { name: "Bar", selected: false, isFilter: false },
        { name: "Hookah", selected: false, isFilter: false },
        { name: "Diet food", selected: false, isFilter: false },
        { name: "Alcoholic drinks", selected: false, isFilter: false },
        { name: "Cooking on the grill", selected: false, isFilter: false },
        { name: "Food from the oven", selected: false, isFilter: false },
        { name: "Kids' meals", selected: false, isFilter: true },
        { name: "Self cooking", selected: false, isFilter: true },
        {
          name: "Food delivery to the room",
          selected: false,
          isFilter: false
        }
      ]
    },
    {
      name: "General",
      items: [
        { name: "Medical staff", selected: false, isFilter: false },
        { name: "Smoking area", selected: false, isFilter: false },
        { name: "Tour agency", selected: false, isFilter: false },
        { name: "Express check-in/out", selected: false, isFilter: false },
        { name: "Private check-in/out", selected: false, isFilter: false },
        { name: "Terminal", selected: false, isFilter: false },
        { name: "Payment by credit card", selected: false, isFilter: false },
        { name: "24-hour front desk", selected: false, isFilter: false },
        { name: "Guarded territory", selected: false, isFilter: false },
        { name: "Conference hall", selected: false, isFilter: false },
        { name: "Organization of events", selected: false, isFilter: false },
        { name: "Parking charge", selected: false, isFilter: false },
        { name: "Parking free", selected: false, isFilter: false },
        {
          name: "Accommodation with animals",
          selected: false,
          isFilter: false
        },
        {
          name: "Children's beds in the room",
          selected: false,
          isFilter: false
        },
        { name: "Room cleaning", selected: false, isFilter: false },
        { name: "Nanny", selected: false, isFilter: false }
      ]
    },
    {
      name: "Languages Spoken",
      items: [
        { name: "English", selected: false, isFilter: false },
        { name: "Russian", selected: false, isFilter: false },
        { name: "Ukrainian", selected: false, isFilter: false }
      ]
    },
    {
      name: "WiFi & Internet",
      items: [
        { name: "In the territory", selected: false, isFilter: true },
        { name: "In the rooms", selected: false, isFilter: true },
        { name: "In the restaurant", selected: false, isFilter: false },
        { name: "In the main building", selected: false, isFilter: false }
      ]
    }
  ],
  additionalFilters: { price: 10500, priceMin: 1500, priceMax: 10500 }
};
