import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

export const HelmetPart = props => {
  const { canonical } = props;
  return (
    <Helmet>
      <link
        rel="alternate"
        type="text/html"
        hrefLang="en"
        href="https://zamistom.kiev.ua/en"
        title="English"
      />
      <link
        rel="alternate"
        type="text/html"
        hrefLang="ru"
        href="https://zamistom.kiev.ua/ru"
        title="Русский"
      />
      <link
        rel="alternate"
        type="text/html"
        hrefLang="uk"
        href="https://zamistom.kiev.ua/ua"
        title="Українська"
      />
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

HelmetPart.propTypes = {
  canonical: PropTypes.string.isRequired
};
