const reviewsDrawerStyled = theme => ({
  list: {
    width: 360,
    padding: 20
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  avatar: {
    width: 60,
    height: 60
  },
  cardHeaderRoot: {
    padding: "8px 0px 0px 8px"
  },
  button: {
    marginBottom: theme.spacing(1)
  }
});

export default reviewsDrawerStyled;
