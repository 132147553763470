const automcompleteStyle = () => ({
  container: {
    //flexGrow: 1,
    position: "relative"
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  searchTypoName: {
    fontWeight: "bold",
    paddingRight: 4
  },
  searchTypoCity: {
    paddingRight: 4
  }
});

export default automcompleteStyle;
