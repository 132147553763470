import React from "react";
import PropTypes from "prop-types";
import { ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/HotelList/servicesStyle";

const useStyles = makeStyles(styles);

export const Activities = props => {
  const { activitiesTranslate, services } = props;
  const classes = useStyles();
  let activitiesText = "";
  let activities = [];
  services.forEach(service => {
    if (service.name === "Activities") {
      service.items.forEach((item, id) => {
        if (item.selected === true) activities.push(activitiesTranslate[id]);
      });
    }
  });
  //slice : if we have many actitivites our card very big, so we need leave only ...
  if (activities.length > 5) activities = activities.slice(0, 5);
  activities.map(
    act => (activitiesText = activitiesText.concat(act).concat(", "))
  );
  //delete , and space
  activitiesText = activitiesText.substr(0, activitiesText.length - 2) + "...";
  return (
    <ListItemText
      className={classes.itemText}
      primary={
        <Typography type="body2" className={classes.itemTextTyp}>
          {activitiesText}
        </Typography>
      }
    />
  );
};

Activities.propTypes = {
  services: PropTypes.array.isRequired,
  activitiesTranslate: PropTypes.array.isRequired,
};
