const hotelGalleryStyle = theme => ({
  //hotel gallery
  svgIcon: {
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
    zIndex: 0,
    padding: 10,
    width: 36,
    height: 36,
    color: "white"
  },
  divCountOfGalleryImg: {
    position: "absolute",
    bottom: 10,
    left: "50%",
    zIndex: 0,
    padding: "5px 15px",
    borderRadius: 15,
    color: "#fff",
    fontSize: 13,
    background: "rgba(0,0,0,.4)",
    transform: "translate3d(-50%,0,0)",
    direction: "ltr"
  },
  media: {
    height: 140,
    minHeigth: "140px",
    [theme.breakpoints.down(900 + theme.spacing(6))]: {
      height: 100,
      minHeigth: "100px"
    }
  },
  mediaHotelPage: {
    height: 250
  },
  mediaRoom: {
    height: 250
    //height: 0,
    // paddingTop: "56.25%" // 16:9
  }
});

export default hotelGalleryStyle;
