import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";

import { Button, Grid, Card, Collapse } from "@material-ui/core";

import styles from "../../_jss/HotelList/hotelListStyle";

import translate from "../../HOC/translate";
import {
  HotelGallery,
  CardHeaderHotelName,
  Reviews,
  CardContentView,
  CardActionView,
  NoResults
} from "./parts";

const ListHotels = props => {
  const {
    classes,
    strings,
    currentLanguage,
    isLoaded,
    hotels,
    hotelsPerPage,
    handleChangeHotelsPerPage,
    handleResetAllFilters
  } = props;
  const [expanded, setExpanded] = React.useState({});

  const handleExpandClick = key => {
    setExpanded({ ...expanded, [key]: !expanded[key] });
  };

  return (
    <React.Fragment>
      {isLoaded && (
        <>
          <Grid container spacing={2}>
            {hotels.slice(0, hotelsPerPage).map((hotel, id) => (
              <Grid key={hotel._id} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Card className={classes.card}>
                  <CardHeaderHotelName
                    hotel={hotel}
                    hotelname={hotel.hotelname}
                    rating={hotel.rating}
                    currentLanguage={currentLanguage}
                  />
                  <HotelGallery
                    imgHotelPathArray={hotel.imgHotelPathArray}
                    hotelname={hotel.hotelname.en}
                    strings={strings}
                    isHotelPage={false}
                  />
                  <CardContentView strings={strings} hotel={hotel} />
                  {/* <Divider variant="middle" /> */}
                  <CardActionView
                    strings={strings}
                    hotel={hotel}
                    currentLanguage={currentLanguage}
                    handleExpandClick={handleExpandClick}
                    id={id}
                    expanded={expanded}
                  />
                  <Collapse in={expanded[id]} timeout="auto" unmountOnExit>
                    <Reviews reviews={hotel.reviews} />
                  </Collapse>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid item xs>
            {hotels.length >= hotelsPerPage && (
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleChangeHotelsPerPage}
                className={classes.buttonShowMoreHotel}
              >
                {strings.buttonShowMoreHotels}
              </Button>
            )}
            {hotels.length === 0 && (
              <NoResults
                handleResetAllFilters={handleResetAllFilters}
                noResult={strings.noResult}
                buttonResetAllFitlersText={strings.buttonResetAllFitlersText}
              />
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

ListHotels.propTypes = {
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  hotels: PropTypes.array.isRequired,
  hotelsPerPage: PropTypes.number.isRequired,
  handleChangeHotelsPerPage: PropTypes.func.isRequired,
  handleResetAllFilters: PropTypes.func.isRequired
};

const ListHotelsCompose = compose(
  withStyles(styles),
  translate("ListHotels")
)(ListHotels);

export { ListHotelsCompose as ListHotels };
