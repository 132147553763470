//340px
import React from "react";
import PropTypes from "prop-types";
import {
  Chip,
  ClickAwayListener,
  Popper,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  Slider
} from "@material-ui/core/";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ArrowDropDown, Close } from "@material-ui/icons";
import styles from "../../../_jss/Filters/filtersStyle";

const useStyles = makeStyles(styles);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#039be5",
    boxShadow: theme.shadows[1],
    fontSize: "0.875rem"
  }
}))(Tooltip);

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });
  return (
    <LightTooltip
      PopperProps={{
        popperRef
      }}
      open={open}
      enterTouchDelay={1}
      placement="top"
      title={`${value} UAH`}
    >
      {children}
    </LightTooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

function PriceChip(props) {
  const {
    classes,
    handleClick,
    handleDelete,
    price,
    maxPrice,
    anchorEl,
    open,
    priceTranslate
  } = props;
  const onDelete = e => {
    handleDelete(e);
    open && handleClick(e);
  };
  switch (price === maxPrice) {
    case true:
      return (
        <Chip
          ref={anchorEl}
          color="primary"
          label={
            <>
              {priceTranslate.name} <ArrowDropDown />
            </>
          }
          onClick={handleClick}
          classes={{ root: classes.chipRootButton, label: classes.chipLabel }}
        />
      );
    case false:
      return (
        <Chip
          ref={anchorEl}
          color="primary"
          onDelete={onDelete}
          label={`${priceTranslate.underPrice} ${price} ${priceTranslate.currency}`}
          onClick={handleClick}
          classes={{ root: classes.chipRoot, label: classes.chipLabel }}
        />
      );
    default:
      return (
        <Chip
          ref={anchorEl}
          color="primary"
          label={
            <>
              {priceTranslate.name} <ArrowDropDown />
            </>
          }
          onClick={handleClick}
          classes={{ root: classes.chipRootButton, label: classes.chipLabel }}
        />
      );
  }
}

PriceChip.propTypes = {
  classes: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  maxPrice: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  priceTranslate: PropTypes.object.isRequired
};

export const PriceDesktop = props => {
  //price: {minPrice: 4500, maxPrice: 220000}},
  const {
    price,
    priceMin,
    priceMax,
    handleChangeCommittedFilterSlider,
    handleResetAdditionalFilter,
    priceTranslate
  } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  let step = Math.round((priceMax - priceMin) / 10);
  if (step > 10000) step = Math.round(step / 10);

  return (
    <>
      <PriceChip
        handleClick={handleClick}
        handleDelete={handleResetAdditionalFilter("price")}
        classes={classes}
        price={price}
        maxPrice={priceMax}
        anchorEl={anchorRef}
        open={open}
        priceTranslate={priceTranslate}
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          modifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent"
            },
            hide: {
              enabled: false
            }
          }}
          placement="bottom-start"
          transition
        >
          <Paper square className={classes.paperStyle}>
            <div className={classes.header}>
              <Typography variant="body1" style={{ padding: "8px" }}>
                {priceTranslate.name}
              </Typography>
              <IconButton
                onClick={handleClick}
                className={classes.button}
                aria-label="Close"
              >
                <Close />
              </IconButton>
            </div>
            {price === priceMax ? (
              <Typography variant="body2" style={{ padding: "8px" }}>
                {priceTranslate.anyPrice}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                style={{ padding: "8px", color: "#039be5" }}
              >
                {priceTranslate.underPrice} {price} {priceTranslate.currency}
              </Typography>
            )}
            <div style={{ margin: "0 16px" }}>
              <Slider
                defaultValue={price}
                onChangeCommitted={handleChangeCommittedFilterSlider("price")}
                aria-label="Custom thumb label"
                ValueLabelComponent={ValueLabelComponent}
                step={step || 500}
                min={priceMin}
                max={priceMax}
                classes={{ thumb: classes.thumb }}
              />
            </div>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

PriceDesktop.propTypes = {
  price: PropTypes.number.isRequired,
  priceMin: PropTypes.number.isRequired,
  priceMax: PropTypes.number.isRequired,
  handleChangeCommittedFilterSlider: PropTypes.func.isRequired,
  handleResetAdditionalFilter: PropTypes.func.isRequired,
  priceTranslate: PropTypes.object.isRequired
};
