import {
  listItemIconRoot,
  listItem,
  listItemTextServiceName,
  listItemText
} from "../General/listStyle";

const serviceDrawerStyle = {
  list: {
    width: 360,
    padding: 20,
    backgroundColor: "white"
  },
  listItemIconRoot,
  listItem,
  listItemTextServiceName,
  listItemText
};

export default serviceDrawerStyle;
