import { createSelector } from "reselect";

//get all hotels
export const getAllHotels = state => state.getHotelsReducer.hotels;
export const getHotelsLoading = state => state.getHotelsReducer.loading;
export const getHotelsError = state => state.getHotelsReducer.error;
export const getHotelsLoaded = state => state.getHotelsReducer.loaded;
//find hotel
export const getFindHotel = state => state.findHotelReducer.hotel;
export const getFindHotelLoading = state => state.findHotelReducer.loading;
export const getFindHotelLoaded = state => state.findHotelReducer.loaded;
export const getFindHotelError = state => state.findHotelReducer.error;
//sorting
export const getSortings = state => state.sortingReducer;
// language
export const getLanguage = state => state.languageReducer.language;
// filters
export const getAllFilters = state => state.filtersReducer.filters;
export const getFilterAccomodation = state => state.filtersReducer.filters[0];
export const getFilterServices = state => state.filtersReducer.filters.slice(1);
// additional filters
export const getPriceFilterObj = state =>
  state.filtersReducer.additionalFilters;
export const getPriceFilter = state =>
  state.filtersReducer.additionalFilters.price;
export const getPriceMinFilter = state =>
  state.filtersReducer.additionalFilters.priceMin;
export const getPriceMaxFilter = state =>
  state.filtersReducer.additionalFilters.priceMax;

export const getTurnOnSorting = createSelector(
  getSortings,
  sortings => {
    let sorting;
    Object.keys(sortings).forEach(nameSorting => {
      if (sortings[nameSorting] === true) sorting = nameSorting;
    });
    return sorting;
  }
);

export const getArrayOfCheckedFilterService = createSelector(
  getFilterServices,
  filterServices => {
    let arrayOfCheckedFilter = [];
    let turnOnServicesCount = 0;
    filterServices.forEach(service => {
      let servTurnOn = service.items.filter(item => item.selected === true);
      if (servTurnOn.length > 0) {
        arrayOfCheckedFilter.push(servTurnOn);
        turnOnServicesCount++;
      } else {
        arrayOfCheckedFilter.push([]);
      }
    });
    return { arrayOfCheckedFilter, turnOnServicesCount };
  }
);

export const filteredHotelByPrice = createSelector(
  getPriceFilterObj,
  getAllHotels,
  (priceFilterObj, hotels) => {
    if (priceFilterObj.price === priceFilterObj.priceMax) {
      return hotels;
    } else {
      return hotels.filter(hotel => {
        return hotel.roomstype.some(room => {
          return room.price < priceFilterObj.price;
        });
      });
    }
  }
);

export const filteredHotelByAccomodation = createSelector(
  getFilterAccomodation,
  getAllHotels,
  (filterAccomod, hotels) => {
    if (filterAccomod.items.every(item => item.selected === false)) {
      return hotels;
    } else {
      return hotels.filter(hotel => {
        return hotel.typeOfAccommod.some((element, index) => {
          return (
            element.selected === true &&
            element.selected === filterAccomod.items[index].selected
          );
        });
      });
    }
  }
);

export const filteredServices = createSelector(
  getFilterServices,
  filteredHotelByAccomodation,
  getArrayOfCheckedFilterService,
  (filterServices, filteredHotelByAccomod, checkedFilterServiceObj) => {
    if (
      !filterServices.some(filter => {
        return filter.items.some(item => {
          return item.selected === true;
        });
      })
    ) {
      return filteredHotelByAccomod;
    } else {
      return filteredHotelByAccomod.filter(hotel => {
        let serviceFilter = hotel.services.filter((service, indexService) => {
          let itemFilter = service.items.filter((item, indexItem) => {
            return (
              item.selected === true &&
              item.selected ===
                filterServices[indexService].items[indexItem].selected
            );
          });
          //arrayOfCheckedFilter with all turn on filter, we need this for case when we use filter
          //with couple item from one service, and we
          //check in service.items.filter -> have the Hotel in some Service this items or not
          //if(itemFilter.length!==0 && itemFilter.length === arrayOfCheckedFilter[indexService].length)
          if (
            itemFilter.length !== 0 &&
            itemFilter.length ===
              checkedFilterServiceObj.arrayOfCheckedFilter[indexService].length
          ) {
            return true;
          } else {
            return false;
          }
        });
        //turnOnServicesCount = calculate count of services for which turn on filter,
        //this serviceFilter.length must return hotel.filter if hotel have this turn on filter
        //if(serviceFilter.length === turnOnServicesCount)
        if (
          serviceFilter.length === checkedFilterServiceObj.turnOnServicesCount
        ) {
          return true;
        } else {
          return false;
        }
      });
    }
  }
);

export const filteredHotelsResult = createSelector(
  filteredServices,
  filteredHotelByPrice,
  (hotelsFilteredByService, hotelsFilteredByPrice) => {
    let intersection = hotelsFilteredByService.filter(x =>
      hotelsFilteredByPrice.includes(x)
    );
    return intersection;
  }
);

export const sortingPrepare = createSelector(
  getSortings,
  sortings => {
    switch (true) {
      case sortings.byPopularity:
        return (hotelA, hotelB) => {
          if (hotelA.rating > hotelB.rating) return -1;
          if (hotelA.rating < hotelB.rating) return 1;
          if (hotelA.rating === hotelB.rating) return 0;
        };
      case sortings.cheapFirst:
        return (hotelA, hotelB) => {
          let result = 0;
          let minPriceA = 0;
          let minPriceB = 0;
          hotelA.roomstype.length > 0 &&
            hotelA.roomstype.forEach(roomA => {
              if (roomA.price <= minPriceA || minPriceA === 0)
                minPriceA = roomA.price;
            });
          hotelB.roomstype.length > 0 &&
            hotelB.roomstype.forEach(roomB => {
              if (roomB.price <= minPriceB || minPriceB === 0)
                minPriceB = roomB.price;
            });
          if (minPriceA > minPriceB) result = 1;
          if (minPriceA < minPriceB) result = -1;
          if (minPriceA === minPriceB) result = 0;
          if (minPriceA === 0) result = 1; //hotelA don't have any price -> delete later
          if (minPriceB === 0) result = -1; //hotelB don't have any price -> delete later

          return result;
        };
      case sortings.expensiveFirst:
        return (hotelA, hotelB) => {
          let result = 0;
          let minPriceA = 0;
          let minPriceB = 0;
          hotelA.roomstype.length > 0 &&
            hotelA.roomstype.forEach(roomA => {
              if (roomA.price <= minPriceA || minPriceA === 0)
                minPriceA = roomA.price;
            });
          hotelB.roomstype.length > 0 &&
            hotelB.roomstype.forEach(roomB => {
              if (roomB.price <= minPriceB || minPriceB === 0)
                minPriceB = roomB.price;
            });
          if (minPriceA > minPriceB) result = -1;
          if (minPriceA < minPriceB) result = 1;
          if (minPriceA === minPriceB) result = 0;
          if (minPriceA === 0) result = 1; //hotelA don't have any price -> delete later
          if (minPriceB === 0) result = -1; //hotelB don't have any price -> delete later

          return result;
        };
      case sortings.byReviewRank:
        return () => {
          return 0;
        };
      case sortings.discountFirst:
        return () => {
          return 0;
        };
      default:
        return () => {
          return 0;
        };
    }
  }
);

export const filteredAndSorteredHotel = createSelector(
  filteredHotelsResult,
  sortingPrepare,
  (filteredhotels, funcOfSorting) => {
    //sort return the same obj, for rerender we need make new obj and we can use slice(0)
    return filteredhotels.slice(0).sort(funcOfSorting);
  }
);
