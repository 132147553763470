import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import {
  Drawer,
  List,
  Tab,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from "@material-ui/core";
import {
  Done,
  GolfCourse,
  Info,
  Chat,
  Wifi,
  Pool,
  LocalDining,
  LocalFlorist,
  Close
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../_jss/HotelPage/serviceDrawerStyle";
import translate from "../../HOC/translate";

const useStyles = makeStyles(styles);

const serviceIcon = [
  <GolfCourse />,
  <Pool />,
  <LocalFlorist />,
  <LocalDining />,
  <Info />,
  <Chat />,
  <Wifi />
];

const ServicesDrawer = props => {
  const classes = useStyles();
  const {
    openServiceDrawer,
    servicesName,
    services,
    strings,
    currentLanguage,
    hotelname,
    clickTabServiceDrawer
  } = props;

  const sideList = (
    <div className={classes.list}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          className={classes.button}
          color="primary"
          aria-label="Close"
        >
          <Close />
        </IconButton>
      </div>
      <Typography variant="h6" aligh="center" color="inherit" component="h6">
        {servicesName} — {hotelname[currentLanguage]}
      </Typography>

      {services.map((service, indexService) => (
        <List key={indexService} dense={true}>
          <ListItem>
            <ListItemIcon className={classes.listItemIconRoot}>
              {serviceIcon[indexService]}
            </ListItemIcon>
            <ListItemText
              className={classes.listItemTextServiceName}
              primary={strings.name[indexService]}
            />
          </ListItem>
          {service.items.map((item, indexItem) => (
            <React.Fragment key={item.name}>
              {item.selected && (
                <ListItem key={item.name} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIconRoot}>
                    <Done fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText
                    primary={strings.services[indexService][indexItem]}
                    className={classes.listItemText}
                  />
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>
      ))}
    </div>
  );

  return (
    <div>
      <Tab onClick={clickTabServiceDrawer(true)} label={servicesName} />
      <Drawer
        anchor="right"
        open={openServiceDrawer}
        onClose={clickTabServiceDrawer(false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={clickTabServiceDrawer(false)}
          onKeyDown={clickTabServiceDrawer(false)}
        >
          {sideList}
        </div>
      </Drawer>
    </div>
  );
};

ServicesDrawer.propTypes = {
  services: PropTypes.array.isRequired,
  hotelname: PropTypes.object.isRequired,
  servicesName: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  clickTabServiceDrawer: PropTypes.func.isRequired,
  openServiceDrawer: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired
};

const ServicesDrawerCompose = compose(translate("Services"))(ServicesDrawer);

export { ServicesDrawerCompose as ServicesDrawer };
