import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Hidden } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import Autocomplete from "./Autocomplete";
import styles from "../../_jss/MainPage/mainPageStyle";

const useStyles = makeStyles(styles);

const SearchHotels = props => {
  const {
    strings,
    handleClickSearch,
    handleClickFilter,
    handleClickMap,
    handleAutocompleteChange,
    hotelnames
  } = props;
  const classes = useStyles();

  return (
    <Grid item className={classes.searchGrid}>
      <Paper elevation={0}>
        <div className={classes.mainPage}>
          <form className={classes.container} noValidate autoComplete="off">
            <Autocomplete
              strings={strings}
              handleAutocompleteChange={handleAutocompleteChange}
              hotelnames={hotelnames}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleClickSearch}
            >
              {strings.buttonLabelSearch}
            </Button>
            <div className={classes.filterMobile}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={handleClickFilter}
              >
                {strings.buttonLabelFilter}
              </Button>
            </div>
            <Button
              startIcon={<Room />}
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={handleClickMap}
            >
              <Hidden only={["sm", "xl", "xs"]}>
                {strings.buttonLabelOnMap}
              </Hidden>
              <Hidden only={["lg", "md"]}>
                {strings.buttonLabelOnMapMobile}
              </Hidden>
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};
// style={{
//   backgroundImage: `url(${mapImg})`
// }}
SearchHotels.propTypes = {
  strings: PropTypes.object.isRequired,
  handleClickFilter: PropTypes.func.isRequired,
  handleClickMap: PropTypes.func.isRequired,
  handleClickSearch: PropTypes.func.isRequired,
  handleAutocompleteChange: PropTypes.func.isRequired,
  hotelnames: PropTypes.array.isRequired
};

export default SearchHotels;

// border: 1px solid #ffffff;
//     border-radius: 4px;
//     background-color: white;
//     padding: 4px;
// }
