const mapBoxStyle = theme => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      color: "#039be5",
      textDecorationLine: "underline"
    }
  },
  card: {
    width: 220,
    height: 200,
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 260,
      height: 250
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonFilter: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      display: "inline-flex"
    }
  },
  buttonCloseMap: {
    textAlign: "right",
    padding: theme.spacing(1)
  },
  searchGrid: {
    margin: "0 auto",
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`
  },
  fullscreenControlStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px"
  },
  navStyle: {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px"
  },
  cardAction: {
    padding: 0
  },
  hotelPinOnMap: {
    display: "block",
    //transform: translate(-10px, -40px),
    minWidth: "10px",
    minHeight: "10px",
    padding: "1px 4px",
    position: "relative",
    margin: "0 0 5px",
    borderRadius: "5px",
    color: "#fff",
    background: "rgba(0, 150, 255, 1)",
    border: "1px solid #e9e5dc",
    whiteSpace: "nowrap",
    cursor: "pointer",
    fill: "#1da1f2cc",
    stroke: "none",
    fontSize: "11px"
  }
});

export default mapBoxStyle;
