const hotelListStyle = theme => ({
  expandOpen: {
    transform: "rotate(180deg)"
  },
  favoriteIcon: {
    marginLeft: "auto"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonShowMoreHotel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

export default hotelListStyle;
