const headerStyle = theme => ({
  displyaInlineBlock: {
    display: "inline-block"
  },
  iconButton: {
    paddingBottom: theme.spacing(2)
  },
  avatarCardHeader: {
    background: "#039be5",
    fontSize: "1.5rem",
    padding: "0 5px",
    color: "white",
    borderRadius: 2,
    display: "inline-block",
    marginRight: theme.spacing(1)
  },
  ListItemRoot: {
    padding: 0
  },
  ListItemText: {
    padding: "0 16px"
  },
  ListItemIconRoot: {
    marginRight: 0,
    minWidth: 0
  },
  ListRoot: {
    paddingBottom: theme.spacing(1)
  }
});

export default headerStyle;
