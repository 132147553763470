import { iconButton, icon } from "../General/ratingStyle";

const cardHeaderHotelNameStyle = () => ({
  title: {
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.87)"
  },
  avatarCardHeader: {
    background: "#039be5",
    fontSize: "1.5rem",
    padding: "0 5px",
    color: "white",
    borderRadius: 2
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      color: "#039be5",
      textDecorationLine: "underline"
    }
  },
  iconButton,
  icon
});

export default cardHeaderHotelNameStyle;
