import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Grid,
  Card,
  CardContent,
  Divider,
  Typography
} from "@material-ui/core";
import {
  LocalDining,
  LocalHotel,
  EventNote,
  MonetizationOn
} from "@material-ui/icons";

import CardHeaderMultiLang from "./CardHeaderMultiLang";
import RoomGallery from "./RoomGallery";
import ListServiceRoom from "./ListServiceRoom";
import translate from "../../HOC/translate";
import styles from "../../_jss/HotelPage/servicesStyle";
const useStyles = makeStyles(styles);

const mainInfoRoomIcon = [<EventNote />, <LocalHotel />, <LocalDining />, null];

const InfoRoom = props => {
  const { data, currentLanguage } = props;
  switch (currentLanguage) {
    case "en":
      return data[0].value;
    case "uk":
      return data[1].value;
    case "ru":
      return data[2].value;
    default:
      return data[0].value;
  }
};

const RoomInfo = props => {
  const { data, currentLanguage, classes, icon, isPrice } = props;
  return (
    <div style={{ display: "inline-block" }}>
      <ListItem classes={{ root: classes.listItemRoot }}>
        {icon && (
          <ListItemIcon className={classes.listItemIconRoomInfo}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          align="justify"
          primary={
            isPrice ? (
              data
            ) : (
              <InfoRoom data={data} currentLanguage={currentLanguage} />
            )
          }
          className={classes.listitemText}
        />
      </ListItem>
    </div>
  );
};

RoomInfo.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired
  ]),
  currentLanguage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.any]),
  isPrice: PropTypes.bool
};

function RoomsListHorizCard(props) {
  const { roomstype, currentLanguage, strings } = props;
  const classes = useStyles();
  return (
    <>
      {roomstype.map((room, index) => (
        <Grid key={index} item xs>
          <Card className={classes.card}>
            <CardHeaderMultiLang
              currentLanguage={currentLanguage}
              roomname={room.roomname}
              size={room.size}
              personcount={room.personcount}
            />
            <RoomGallery
              imgs={room.imgs}
              roomname={room.roomname[0].value}
              strings={strings}
            />
            <CardContent>
              <List dense={false}>
                <Typography variant="subtitle1">
                  {strings.descriptionHeader}
                </Typography>
                <RoomInfo
                  data={room.description}
                  currentLanguage={currentLanguage}
                  icon={mainInfoRoomIcon[4]}
                  classes={classes}
                />
                <Divider variant="middle" />
                {room.price && (
                  <RoomInfo
                    data={
                      strings.roomPriceText1 +
                      room.price +
                      strings.roomPriceText2
                    }
                    currentLanguage={currentLanguage}
                    icon={<MonetizationOn />}
                    classes={classes}
                    isPrice={true}
                  />
                )}
                {room.food[0].value && (
                  <RoomInfo
                    data={room.food}
                    currentLanguage={currentLanguage}
                    icon={mainInfoRoomIcon[2]}
                    classes={classes}
                  />
                )}

                {room.beds[0].value && (
                  <RoomInfo
                    data={room.beds}
                    currentLanguage={currentLanguage}
                    icon={mainInfoRoomIcon[1]}
                    classes={classes}
                  />
                )}
                {room.rules[0].value && (
                  <RoomInfo
                    data={room.rules}
                    currentLanguage={currentLanguage}
                    icon={mainInfoRoomIcon[0]}
                    classes={classes}
                  />
                )}
              </List>
              <Divider variant="middle" />
              <Grid container direction="row">
                {room.services.map((service, indexService) => (
                  <Grid item key={indexService}>
                    <ListServiceRoom
                      strings={strings}
                      indexService={indexService}
                      service={service}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
}

RoomsListHorizCard.propTypes = {
  roomstype: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired
};

const RoomsListHorizCardCompose = compose(translate("RoomsType"))(
  RoomsListHorizCard
);

export { RoomsListHorizCardCompose as RoomsListHorizCard };
