import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../_jss/MainPage/mainPageStyle";
import { Button, ButtonGroup } from "@material-ui/core";

const useStyles = makeStyles(styles);

export const Sorting = props => {
  const classes = useStyles();
  const { stateShowMap, onClick, sortingTrue, strings } = props;
  return (
    <div className={stateShowMap ? classes.sortingMap : classes.sorting}>
      <ButtonGroup variant="text" size="small">
        <Button
          className={
            sortingTrue === "byPopularity"
              ? classes.sortingButtonTurnOn
              : classes.sortingButton
          }
          onClick={onClick("byPopularity")}
        >
          {strings.byPopularity}
        </Button>
        <Button
          className={
            sortingTrue === "cheapFirst"
              ? classes.sortingButtonTurnOn
              : classes.sortingButton
          }
          onClick={onClick("cheapFirst")}
        >
          {strings.cheapFirst}
        </Button>
        <Button
          className={
            sortingTrue === "expensiveFirst"
              ? classes.sortingButtonTurnOn
              : classes.sortingButton
          }
          onClick={onClick("expensiveFirst")}
        >
          {strings.expensiveFirst}
        </Button>
      </ButtonGroup>
    </div>
  );
};

Sorting.propTypes = {
  stateShowMap: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  sortingTrue: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired
};
