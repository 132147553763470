import React from "react";
import PropTypes from "prop-types";
import { ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/HotelList/servicesStyle";

const useStyles = makeStyles(styles);

export const FoodAndDrink = props => {
  const { services, foodAndDrinkTranslate } = props;
  const classes = useStyles();
  let foodAndDrink = "";
  services.forEach(service => {
    if (service.name === "Food & Drink") {
      if (service.items.length > 5) service.items = service.items.slice(0, 5);
      service.items.forEach((item, id) => {
        if (item.selected === true)
          foodAndDrink = foodAndDrink
            .concat(foodAndDrinkTranslate[id])
            .concat(", ");
      });
    }
  });
  foodAndDrink = foodAndDrink.substr(0, foodAndDrink.length - 2) + "...";
  return (
    <ListItemText
      className={classes.itemText}
      primary={
        <Typography type="body2" className={classes.itemTextTyp}>
          {foodAndDrink}
        </Typography>
      }
    />
  );
};

FoodAndDrink.propTypes = {
  services: PropTypes.array.isRequired,
  foodAndDrinkTranslate: PropTypes.array.isRequired
};
