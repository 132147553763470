const CHANGE_TYPE_SORTING = "zamistom.kiev.ua/sorting/CHANGE_TYPE_SORTING";

export const changeTypeSorting = sorting => ({
  type: CHANGE_TYPE_SORTING,
  sorting
});

const initialState = {
  byPopularity: true,
  cheapFirst: false,
  expensiveFirst: false,
  byReviewRank: false,
  discountFirst: false
};

export const sortingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TYPE_SORTING:
      Object.keys(state).forEach(key => {
        state[key] = false;
      });
      return {
        ...state,
        [action.sorting]: true
      };
    default:
      return state;
  }
};
