import { iconButton, icon } from "../General/ratingStyle";

const reviewsStyle = () => ({
  root: {
    boxShadow: "none"
  },
  avatar: {
    width: 60,
    height: 60
  },
  paperReviewWithScroll: {
    maxHeight: 250,
    overflow: "auto",
    boxShadow: "none",
    scrollbarWidth: "thin"
  },
  cardHeaderRoot: {
    padding: "8px 0px 0px 8px"
  },
  cardContentRoot: {
    padding: "0px 0px 24px 8px"
  },
  iconButton,
  icon
});

export default reviewsStyle;
