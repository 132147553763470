import React from "react";
import PropTypes from "prop-types";
import {
  CardContent,
  ListItem,
  List,
  ListItemIcon,
  Typography,
  Divider
} from "@material-ui/core";
import { LocalDining, Home, Rowing } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/HotelList/cardContentStyle";
import { TypeOfAccomodation, FoodAndDrink, Activities } from "./";

const useStyles = makeStyles(styles);

export const CardContentView = props => {
  const { strings, hotel } = props;
  const classes = useStyles();
  let hotelRoomMinPrice = 0;
  hotel.roomstype.forEach(room => {
    if (room.price < hotelRoomMinPrice || hotelRoomMinPrice === 0)
      hotelRoomMinPrice = room.price;
  });
  return (
    <CardContent className={classes.cardContent}>
      {hotelRoomMinPrice !== 0 && (
        <>
          <span className={classes.spanFrom}>{strings.priceFrom}</span>
          <Typography component="span" className={classes.spanPrice}>
            {hotelRoomMinPrice}
          </Typography>
          <div className={classes.divBlockUahNight}>
            <div className={classes.divUAH}>UAH</div>
            <div className={classes.divNight}>{strings.priceNight}</div>
          </div>
          <Divider variant="middle" />
        </>
      )}
      <List dense={true}>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIconRoot}>
            <Home />
          </ListItemIcon>
          <TypeOfAccomodation
            typeOfAccommod={hotel.typeOfAccommod}
            typeOfAccommodTranslate={strings.typeOfAccommodation}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIconRoot}>
            <LocalDining />
          </ListItemIcon>
          <FoodAndDrink
            services={hotel.services}
            foodAndDrinkTranslate={strings.foodAndDrink}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIconRoot}>
            <Rowing />
          </ListItemIcon>
          <Activities
            services={hotel.services}
            activitiesTranslate={strings.activities}
          />
        </ListItem>
      </List>
    </CardContent>
  );
};

CardContentView.propTypes = {
  strings: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired
};
