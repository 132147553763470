import React from "react";
import PropTypes from "prop-types";

import ReactBnbGallery from "react-bnb-gallery";

import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, CardActionArea, SvgIcon } from "@material-ui/core";

import styles from "../../_jss/General/galleryStyle";
import { config } from "../../_config";

const useStyles = makeStyles(styles);

const RoomGallery = props => {
  const { imgs, roomname, strings } = props;
  const [galleryOpened, setGalleryOpened] = React.useState(false);
  const classes = useStyles();
  const toggleGallery = () => {
    setGalleryOpened(!galleryOpened);
  };

  let photos = [];
  imgs.forEach(img => {
    photos.push({
      photo: config.urlImgRoom + img,
      caption: roomname,
      thumbnail: config.urlImgRoomThumbnail + img
    });
  });
  return (
    <>
      <CardActionArea onClick={toggleGallery}>
        <CardMedia
          className={classes.mediaRoom}
          image={config.urlImgRoom + imgs[0]}
          title={roomname}
        />
        <SvgIcon classes={{ root: classes.svgIcon }}>
          <path d="M11 15v-2h1v2c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h2v1H1v10h10zm3.615-15C15.38 0 16 .62 16 1.385v9.23C16 11.38 15.38 12 14.615 12h-9.23C4.62 12 4 11.38 4 10.615v-9.23C4 .62 4.62 0 5.385 0h9.23zm.462 10.615v-9.23c0-.255-.207-.462-.462-.462h-9.23c-.255 0-.462.207-.462.462v9.23c0 .255.207.462.462.462h9.23c.255 0 .462-.207.462-.462z" />
        </SvgIcon>
        <div className={classes.divCountOfGalleryImg}>
          1 {strings.countOfGalleryImg} {photos.length}
        </div>
      </CardActionArea>
      <ReactBnbGallery
        show={galleryOpened}
        photos={photos}
        onClose={toggleGallery}
      />
    </>
  );
};

RoomGallery.propTypes = {
  strings: PropTypes.object.isRequired,
  roomname: PropTypes.string.isRequired,
  imgs: PropTypes.array.isRequired
};

export default RoomGallery;
