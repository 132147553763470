const cardContentStyle = theme => ({
  cardContent: {
    height: 200,
    padding: theme.spacing(1)
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  listItemIconRoot: {
    minWidth: 32
  },
  spanFrom: {
    paddingLeft: theme.spacing(2),
    marginRight: "5px"
  },
  spanPrice: {
    fontSize: theme.spacing(4),
    color: "#039be5"
  },
  divBlockUahNight: {
    display: "inline-block",
    marginLeft: "5px"
  },
  divUAH: {
    fontSize: theme.spacing(2),
    lineHeight: 1,
    fontWeight: "500"
  },
  divNight: {
    fontSize: theme.spacing(1.5),
    lineHeight: 1
  }
});

export default cardContentStyle;
