const servicesStyle = theme => ({
  itemText: {
    padding: 0
  },
  itemTextTyp: {
    fontSize: "0.9rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.8125rem"
    }
  }
});

export default servicesStyle;
