import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//import Language from '@material-ui/icons/Language';

import { changeLanguage } from "../_reducers/ducks-language";
import { getLanguage } from "../_reducers/selectors";

const styles = theme => ({
  labellIconButton: {
    fontSize: "1rem"
  },
  imgFlag: {
    paddingLeft: theme.spacing(0.5),
    width: 32,
    height: 16
  }
});
function TextAndFlag(props) {
  const { currentLanguage, classes } = props;
  switch (currentLanguage) {
    case "uk":
      return (
        <>
          UA
          <img
            className={classes.imgFlag}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAwIiBoZWlnaHQ9IjgwMCI+PHJlY3Qgd2lkdGg9IjEyMDAiIGhlaWdodD0iODAwIiBmaWxsPSIjMDA1QkJCIi8+PHJlY3Qgd2lkdGg9IjEyMDAiIGhlaWdodD0iNDAwIiB5PSI0MDAiIGZpbGw9IiNGRkQ1MDAiLz48L3N2Zz4="
            alt="UA"
          />
        </>
      );
    case "ru":
      return (
        <>
          RU
          <img
            className={classes.imgFlag}
            src="data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI0MDAiIGhlaWdodD0iMjY2IiB2aWV3Qm94PSIwLCAwLCA0MDAsMjY2Ij48ZyBpZD0ic3ZnZyI+PHBhdGggaWQ9InBhdGgwIiBkPSJNMC4wMDAgNDQuMDQ1IEwgMC4wMDAgODguMDkwIDIwMC4wMDAgODguMDkwIEwgNDAwLjAwMCA4OC4wOTAgNDAwLjAwMCA0NC4wNDUgTCA0MDAuMDAwIDAuMDAwIDIwMC4wMDAgMC4wMDAgTCAwLjAwMCAwLjAwMCAwLjAwMCA0NC4wNDUgIiBzdHJva2U9Im5vbmUiIGZpbGw9IiNmY2ZjZmMiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PC9wYXRoPjxwYXRoIGlkPSJwYXRoMSIgZD0iTTAuMDAwIDIyMi4wODkgTCAwLjAwMCAyNjYuMTM0IDIwMC4wMDAgMjY2LjEzNCBMIDQwMC4wMDAgMjY2LjEzNCA0MDAuMDAwIDIyMi4wODkgTCA0MDAuMDAwIDE3OC4wNDQgMjAwLjAwMCAxNzguMDQ0IEwgMC4wMDAgMTc4LjA0NCAwLjAwMCAyMjIuMDg5ICIgc3Ryb2tlPSJub25lIiBmaWxsPSIjZDQyYzFjIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjwvcGF0aD48cGF0aCBpZD0icGF0aDIiIGQ9Ik0wLjAwMCAxMzMuMDY3IEwgMC4wMDAgMTc2LjE4MSAyMDAuMDAwIDE3Ni4xODEgTCA0MDAuMDAwIDE3Ni4xODEgNDAwLjAwMCAxMzMuMDY3IEwgNDAwLjAwMCA4OS45NTMgMjAwLjAwMCA4OS45NTMgTCAwLjAwMCA4OS45NTMgMC4wMDAgMTMzLjA2NyAiIHN0cm9rZT0ibm9uZSIgZmlsbD0iIzA0M2NhNCIgZmlsbC1ydWxlPSJldmVub2RkIj48L3BhdGg+PHBhdGggaWQ9InBhdGgzIiBkPSJNMC4wMDAgMTc3LjExMiBMIDAuMDAwIDE3OC4wNDQgMjAwLjAwMCAxNzguMDQ0IEwgNDAwLjAwMCAxNzguMDQ0IDQwMC4wMDAgMTc3LjExMiBMIDQwMC4wMDAgMTc2LjE4MSAyMDAuMDAwIDE3Ni4xODEgTCAwLjAwMCAxNzYuMTgxIDAuMDAwIDE3Ny4xMTIgIiBzdHJva2U9Im5vbmUiIGZpbGw9IiM0YzM0N2MiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PC9wYXRoPjxwYXRoIGlkPSJwYXRoNCIgZD0iTTAuMDAwIDg5LjAyMiBMIDAuMDAwIDg5Ljk1MyAyMDAuMDAwIDg5Ljk1MyBMIDQwMC4wMDAgODkuOTUzIDQwMC4wMDAgODkuMDIyIEwgNDAwLjAwMCA4OC4wOTAgMjAwLjAwMCA4OC4wOTAgTCAwLjAwMCA4OC4wOTAgMC4wMDAgODkuMDIyICIgc3Ryb2tlPSJub25lIiBmaWxsPSIjNTQ3Y2M0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjwvcGF0aD48L2c+PC9zdmc+"
            alt="RU"
          />
        </>
      );
    case "en":
      return (
        <>
          EN
          <img
            className={classes.imgFlag}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCAzMCIgd2lkdGg9IjEyMDAiIGhlaWdodD0iNjAwIj48Y2xpcFBhdGggaWQ9InQiPjxwYXRoIGQ9Ik0zMCwxNSBoMzAgdjE1IHogdjE1IGgtMzAgeiBoLTMwIHYtMTUgeiB2LTE1IGgzMCB6Ii8+PC9jbGlwUGF0aD48cGF0aCBkPSJNMCwwIHYzMCBoNjAgdi0zMCB6IiBmaWxsPSIjMDAyNDdkIi8+PHBhdGggZD0iTTAsMCBMNjAsMzAgTTYwLDAgTDAsMzAiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSI2Ii8+PHBhdGggZD0iTTAsMCBMNjAsMzAgTTYwLDAgTDAsMzAiIGNsaXAtcGF0aD0idXJsKCN0KSIgc3Ryb2tlPSIjY2YxNDJiIiBzdHJva2Utd2lkdGg9IjQiLz48cGF0aCBkPSJNMzAsMCB2MzAgTTAsMTUgaDYwIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMTAiLz48cGF0aCBkPSJNMzAsMCB2MzAgTTAsMTUgaDYwIiBzdHJva2U9IiNjZjE0MmIiIHN0cm9rZS13aWR0aD0iNiIvPjwvc3ZnPg=="
            alt="EN"
          />
        </>
      );
    default:
      return (
        <>
          Укр
          <img
            className={classes.imgFlag}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAwIiBoZWlnaHQ9IjgwMCI+PHJlY3Qgd2lkdGg9IjEyMDAiIGhlaWdodD0iODAwIiBmaWxsPSIjMDA1QkJCIi8+PHJlY3Qgd2lkdGg9IjEyMDAiIGhlaWdodD0iNDAwIiB5PSI0MDAiIGZpbGw9IiNGRkQ1MDAiLz48L3N2Zz4="
            alt="UA"
          />
        </>
      );
  }
}
class ChangeLanguages extends Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLangChoose = event => {
    const { lang } = event.target;
    this.setState({ anchorEl: null });
    this.props.changeLanguage(lang);
  };

  render() {
    const { classes, strings, currentLanguage } = this.props;
    const { anchorEl } = this.state;
    return (
      <React.Fragment>
        <Tooltip title={strings.tooltipChangeLang}>
          <IconButton
            onClick={this.handleMenuOpen}
            classes={{ label: classes.labellIconButton }}
            color="primary"
            aria-label="Menu"
            aria-owns={anchorEl ? "language-menu" : undefined}
          >
            <TextAndFlag currentLanguage={currentLanguage} classes={classes} />
            {/* <Language /> */}
          </IconButton>
        </Tooltip>
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem lang="uk" onClick={this.handleLangChoose}>
            UA / Українська
          </MenuItem>
          <MenuItem lang="ru" onClick={this.handleLangChoose}>
            RU / Русский
          </MenuItem>
          <MenuItem lang="en" onClick={this.handleLangChoose}>
            EN / English
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

ChangeLanguages.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentLanguage: getLanguage(state)
});

const mapDispatchToProps = {
  changeLanguage
};

const ChangeLanguagesCompose = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChangeLanguages);
export { ChangeLanguagesCompose as ChangeLanguages };
