import React from "react";
import PropTypes from "prop-types";
import Rating from "material-ui-rating";

import { Card, CardHeader, CardContent, Avatar } from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../_jss/HotelPage/reviewsDrawerStyled";

const useStyles = makeStyles(styles);

export const Reviews = props => {
  const { reviews } = props;
  const classes = useStyles();
  return (
    <>
      {reviews.map((review, indexReview) => (
        <Card key={indexReview}>
          <CardHeader
            classes={{ root: classes.cardHeaderRoot }}
            avatar={
              <Avatar
                src={review.profile_photo_url}
                className={classes.avatar}
              />
            }
            title={review.author_name}
            subheader={review.relative_time_description}
          />
          <CardContent>
            <Rating readOnly value={review.rating} max={5} />
            {review.text}
          </CardContent>
        </Card>
      ))}
    </>
  );
};

Reviews.propTypes = {
  reviews: PropTypes.array.isRequired
};
