const CHANGE_LANGUAGE = "zamistom.kiev.ua/language/CHANGE_LANGUAGE";

export const changeLanguage = language => ({ type: CHANGE_LANGUAGE, language });

const initialState = { language: "uk" };

export const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.language };
    default:
      return state;
  }
};
