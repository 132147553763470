import React from "react";
import PropTypes from "prop-types";
import CardHeader from "@material-ui/core/CardHeader";

function CardHeaderMultiLang(props) {
  const { roomname, size, personcount } = props;
  switch (props.currentLanguage) {
    case "en":
      return (
        <CardHeader
          title={roomname[0].value}
          subheader={"Room size " + size + " m2, Pax: " + personcount}
        />
      );
    case "uk":
      return (
        <CardHeader
          title={roomname[1].value}
          subheader={"Розмір номера " + size + " м2, Людей: " + personcount}
        />
      );
    case "ru":
      return (
        <CardHeader
          title={roomname[2].value}
          subheader={"Размер номера " + size + " м2, Человек: " + personcount}
        />
      );
    default:
      return (
        <CardHeader title={roomname[0].value} subheader={"Room size " + size} />
      );
  }
}

CardHeaderMultiLang.propTypes = {
  roomname: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  personcount: PropTypes.number.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

export default CardHeaderMultiLang;
