import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, CircularProgress } from "@material-ui/core/";
import {
  MetaHeadHelmet,
  Services,
  TabsSection,
  Header,
  Reviews,
  BreadCrumbsSection
} from "./partsHotelPage";
import { HotelGallery } from "../MainPage/ListHotels/parts";
import MyGoogleMap from "../GoogleMap/MyGoogleMap";
import { RoomsListHorizCard } from "./Rooms/RoomsListHorizCard";
import { RoomsListVertCart } from "./Rooms/RoomsListVertCart";
import { BottomPart } from "../BottomPart/BottomPart";
import { history } from "../_helpers";
import {
  getFindHotel,
  getFindHotelLoaded,
  getFindHotelLoading
} from "../_reducers/selectors";
import translate from "../HOC/translate";
import { changeLanguage } from "../_reducers/ducks-language";
import { findHotelByName } from "../_reducers/ducks-hotelpage";
import styles from "../_jss/HotelPage/hotelPageStyle";

const Description = ({ currentLanguage, description }) => {
  switch (currentLanguage) {
    case "en":
      return description[0].value;
    case "uk":
      return description[1].value;
    case "ru":
      return description[2].value;
    default:
      return description[0].value;
  }
};

function descMetaHead(currentLanguage, description) {
  switch (currentLanguage) {
    case "en":
      return description[0].value.slice(0, 120).trim() + "...";
    case "uk":
      return description[1].value.slice(0, 120).trim() + "...";
    case "ru":
      return description[2].value.slice(0, 120).trim() + "...";
    default:
      return description[0].value.slice(0, 120).trim() + "...";
  }
}

class HotelPage extends Component {
  componentDidMount() {
    const langUrl = this.props.match.path.substr(1, 2);
    langUrl !== "ua" && this.props.changeLanguage(langUrl);
    this.props.findHotelByName(this.props.match.params.searchQuery);
  }

  handleClickBread = event => {
    event.preventDefault();
    history.push("/");
  };

  render() {
    const {
      classes,
      currentLanguage,
      strings,
      hotel,
      isLoadedHotel
    } = this.props;
    const hotelnameUrl = this.props.match.params.searchQuery;
    const langUrl = this.props.match.path.substr(1, 2);
    let descriptionMetaHead = "";
    if (isLoadedHotel) {
      descriptionMetaHead = descMetaHead(currentLanguage, hotel.description);
    }
    return (
      <>
        <Grid item className={classes.layout}>
          {isLoadedHotel ? (
            <Grid container spacing={1}>
              <MetaHeadHelmet
                hotelname={hotel.hotelname[currentLanguage]}
                hotelnameUrl={hotelnameUrl}
                strings={strings}
                description={descriptionMetaHead}
                langUrl={langUrl}
              />
              <Grid item xs={12}>
                <BreadCrumbsSection
                  hotel={hotel}
                  handleClickBread={this.handleClickBread}
                  currentLanguage={currentLanguage}
                  strings={strings}
                />
              </Grid>
              <Grid item xs={12}>
                <TabsSection
                  hotel={hotel}
                  strings={strings}
                  currentLanguage={currentLanguage}
                />
              </Grid>
              <Grid item xs={12}>
                <Header hotel={hotel} currentLanguage={currentLanguage} />
              </Grid>
              <Grid item xs={12}>
                <HotelGallery
                  imgHotelPathArray={hotel.imgHotelPathArray}
                  hotelname={hotel.hotelname.en}
                  strings={strings}
                  isHotelPage={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.hotelTitle}
                  variant="h6"
                  align="left"
                  color="inherit"
                  component="h4"
                >
                  {strings.descriptionTitle}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography
                  className={classes.textNewLine}
                  variant="body1"
                  align="justify"
                  color="inherit"
                  component="p"
                >
                  <Description
                    currentLanguage={currentLanguage}
                    description={hotel.description}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MyGoogleMap
                  latitude={hotel.latitude}
                  longitude={hotel.longitude}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="inherit" component="h6">
                  {strings.servicesTitle + hotel.hotelname[currentLanguage]}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Services
                  services={hotel.services}
                  hotelname={hotel.hotelname}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="inherit"
                  component="h6"
                  id="typeOfAccommod"
                >
                  {strings.roomsTypeTitle}
                </Typography>
              </Grid>

              {hotel.roomstype.length > 1 ? (
                <Grid item xs={12}>
                  <RoomsListVertCart roomstype={hotel.roomstype} />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <RoomsListHorizCard roomstype={hotel.roomstype} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  aligh="center"
                  color="inherit"
                  component="h6"
                >
                  {strings.reviewsTitle}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Reviews reviews={hotel.reviews} />
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center">
              <CircularProgress className={classes.progress} />
            </Grid>
          )}
        </Grid>
        <BottomPart />
      </>
    );
  }
}

HotelPage.propTypes = {
  classes: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  findHotelByName: PropTypes.func.isRequired,
  isLoadedHotel: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  hotel: getFindHotel(state),
  isLoading: getFindHotelLoading(state),
  isLoadedHotel: getFindHotelLoaded(state)
});

const mapDispatchToProps = {
  changeLanguage,
  findHotelByName
};

const HotelPageCompose = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  translate("HotelPage")
)(HotelPage);

export { HotelPageCompose as HotelPage };
