import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import { CardHeaderHotelName } from "./";
import styles from "../../_jss/MapBox/mapBoxStyle";
const useStyles = makeStyles(styles);

export const HotelInfoOnMouseOver = props => {
  const { hotel, currentLanguage } = props;
  const classes = useStyles();
  return (
    <Card>
      <Link
        to={{
          pathname:
            currentLanguage !== "uk"
              ? `${currentLanguage}/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
              : `ua/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
        }}
        target="_blank"
        className={classes.link}
      >
        <CardHeaderHotelName
          hotelname={hotel.hotelname}
          rating={hotel.rating}
          currentLanguage={currentLanguage}
        />
      </Link>
    </Card>
  );
};

HotelInfoOnMouseOver.propTypes = {
  hotel: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired
};
