export default {
  MetaHead: {
    title: "Find a hotels, cottages for a holiday outside the city on ",
    metaDesc1: "Find cheap holiday online at ",
    metaDesc2: "Hotels, cottages and villas at low prices!",
    metaPropOgTitle: "Looking for a holiday outside the city? Check on ",
    metaPropOgDesc:
      "Search for hotels, recreation, cottages and villas. Reviews, ratings, prices. Find a vacation right now!",
    metaPropOgLocale: "en_GB",
    metaTwitterTitle: "ooking for a holiday outside the city? Check on ",
    metaTwitterDesc:
      "Search for hotels, recreation, cottages and villas. Reviews, ratings, prices. Find a vacation right now!"
  },
  BottomPart: {
    textDesc: " - hotels, cottages for rest near Kiev.."
  },
  MapBox: {
    buttonLabelFilter: "Filters",
    buttonLabelClose: "Close Map",
    countOfGalleryImg: " from ",
    buttonDetailsText: "Read more",
    buttonCloseCard: "Close"
  },
  Navbar: {
    tooltipChangeLang: "Change language",
    tooltipEnerOnSite: "Login and Registration"
  },
  RoomsType: {
    descriptionHeader: "About the room",
    roomsTypeHeader: "Available rooms",
    name: ["Bathroom", "Room Facilities"],
    services: [
      [
        "Shower",
        "Bathroom",
        "Bidet",
        "Hygienic shower",
        "Toilet",
        "Free toiletries",
        "Towels",
        "Bathrobe",
        "Hairdryer",
        "Disposable brushes",
        "Jacuzzi",
        "Washer"
      ],
      [
        "Minibar",
        "DVD Player",
        "Heating radiators",
        "Slippers",
        "Satellite channels",
        "Internet",
        "Flat-screen TV",
        "Hardwood floors",
        "Linens",
        "Mosquito net",
        "Wardrobe",
        "Writing/coffee table",
        "Air conditioning",
        "Terrace",
        "Balcony",
        "Carpeted",
        "Floor windows",
        "Sofa couch",
        "Armchairs",
        "Electric kettle",
        "Microwave",
        "Fridge",
        "Plate",
        "Cutlery"
      ]
    ],
    roomPriceText1: "Price per night from: ",
    roomPriceText2: " UAH",
    countOfGalleryImg: " from "
  },
  Services: {
    name: [
      "Activities",
      "Pool & SPA",
      "Territory",
      "Food & Drink",
      "General",
      "Languages Spoken",
      "WiFi & Internet"
    ],
    services: [
      [
        "Beach",
        "Boat/catamaran rental",
        "GyroScooter rental",
        "Tennis court",
        "Kids' club",
        "Playground",
        "Horseback riding",
        "Cycling routes",
        "Bicycle rental",
        "Darts",
        "Ping-pong",
        "Pool table",
        "Fishing",
        "Zoo",
        "Paintball",
        "Rope park",
        "Trampoline"
      ],
      [
        "Solarium",
        "Massage",
        "SPA area",
        "Beauty parlor",
        "Bath: russian/finnish",
        "Sauna",
        "Pool",
        "Indoor pool",
        "Children's pool",
        "Heated swimming pool"
      ],
      [
        "Forest",
        "Garden",
        "Terrace",
        "BBQ facilities",
        "Rental skewers/barbecue",
        "Altanka rental",
        "Altanka for free",
        "Benches",
        "Lawn",
        "Apiary",
        "Food shop",
        "Gift shop",
        "Pond",
        "Lake",
        "River"
      ],
      [
        "Restaurant",
        "Cafe",
        "Bar",
        "Hookah",
        "Diet food",
        "Alcoholic drinks",
        "Cooking on the grill",
        "Food from the oven",
        "Kids' meals",
        "Self cooking",
        "Food delivery to the room"
      ],
      [
        "Medical staff",
        "Smoking area",
        "Tour agency",
        "Express check-in/out",
        "Private check-in/out",
        "Terminal",
        "Payment by credit card",
        "24-hour front desk",
        "Guarded territory",
        "Conference hall",
        "Organization of events",
        "Parking charge",
        "Parking free",
        "Accommodation with animals",
        "Children's beds in the room",
        "Room cleaning",
        "Nanny"
      ],
      ["English", "Russian", "Ukrainian"],
      [
        "In the territory",
        "In the rooms",
        "In the restaurant",
        "In the main building"
      ]
    ]
  },
  ListHotels: {
    title:
      "Not only hotels ... Discover the perfect comfort of houses and apartments",
    distanceFromKiev: "Distance from Kiev : ",
    typeOfAccommodationText: "Type of accommodation : ",
    typeOfAccommodation: [
      "Cottage",
      "Bungalow",
      "Main Building",
      "Villa",
      "Tent"
    ],
    foodAndDrinkText: "Food and Drink : ",
    foodAndDrink: [
      "Restaurant",
      "Cafe",
      "Bar",
      "Hookah",
      "Diet food",
      "Alcoholic drinks",
      "Cooking on the grill",
      "Food from the oven",
      "Kids' meals",
      "Self cooking",
      "Food delivery to the room"
    ],
    activitiesText: "Entertainment: ",
    activities: [
      "Beach",
      "Boat/catamaran rental",
      "GyroScooter rental",
      "Tennis court",
      "Kids' club",
      "Playground",
      "Horseback riding",
      "Cycling routes",
      "Bicycle rental",
      "Darts",
      "Ping-pong",
      "Pool table",
      "Fishing",
      "Zoo",
      "Paintball",
      "Rope park",
      "Trampoline"
    ],
    descriptionTitle: "About the Complex",
    buttonDetailsText: "Read more",
    buttonReviewText: "Reviews",
    buttonShowMoreHotels: "SHOW MORE HOTELS",
    countOfGalleryImg: " from ",
    noResult: "No result found!",
    buttonResetAllFitlersText: "CLEAR ALL FILTERS",
    priceFrom: "from",
    priceNight: "night"
  },
  HotelPage: {
    title1: "Hotel ",
    title2: ". Rest near Kiev, country rest",
    descriptionTitle: "About the Complex",
    servicesTitle: "Services — ",
    roomsTypeTitle: "Available rooms",
    reviewsTitle: "Guest reviews",
    tabs: {
      description: "Overview",
      services: "Facilities",
      placement: "Rooms",
      reviews: "Reviews"
    },
    // tabs : {description : 'Description', services: 'Facilities and Services', placement: 'Terms of placement',
    // reviews: 'Guest reviews',},
    countOfGalleryImg: " from ",
    breadCrumbsMain: "Main Page"
  },
  MainPage: {
    popDest:
      "What kind of accommodation would you look for, you will find it with us ..."
  },
  UserMenu: {
    personalAccount: "Personal account",
    orders: "Orders",
    discount: "Discount",
    leave: "Logout"
  },
  Search: {
    buttonLabelFilter: "Filters",
    buttonLabelSearch: "Search",
    buttonLabelOnMap: "Show map",
    buttonLabelOnMapMobile: "Map",
    textFieldLabel: "Search",
    //textFieldPlaceholder : 'District area, city',
    textFieldPlaceholder: "Hotel or city",
    filter: {
      buttonLabelClose: "Close"
    },
    servicesName: [
      "Accommodation",
      "Activities",
      "Pool & SPA",
      "Territory",
      "Food & Drink",
      "General",
      "Languages Spoken",
      "WiFi & Internet"
    ],
    services: [
      ["Cottage", "Bungalow", "Main Building", "Villa", "Tent"],
      [
        "Beach",
        "Boat/catamaran rental",
        "GyroScooter rental",
        "Tennis court",
        "Kids' club",
        "Playground",
        "Horseback riding",
        "Cycling routes",
        "Bicycle rental",
        "Darts",
        "Ping-pong",
        "Pool table",
        "Fishing",
        "Zoo",
        "Paintball",
        "Rope park",
        "Trampoline"
      ],
      [
        "Solarium",
        "Massage",
        "SPA area",
        "Beauty parlor",
        "Bath: russian/finnish",
        "Sauna",
        "Pool",
        "Indoor pool",
        "Children's pool",
        "Heated swimming pool"
      ],
      [
        "Forest",
        "Garden",
        "Terrace",
        "BBQ facilities",
        "Rental skewers/barbecue",
        "Altanka rental",
        "Altanka for free",
        "Benches",
        "Lawn",
        "Apiary",
        "Food shop",
        "Gift shop",
        "Pond",
        "Lake",
        "River"
      ],
      [
        "Restaurant",
        "Cafe",
        "Bar",
        "Hookah",
        "Diet food",
        "Alcoholic drinks",
        "Cooking on the grill",
        "Food from the oven",
        "Kids' meals",
        "Self cooking",
        "Food delivery to the room"
      ],
      [
        "Medical staff",
        "Smoking area",
        "Tour agency",
        "Express check-in/out",
        "Private check-in/out",
        "Terminal",
        "Payment by credit card",
        "24-hour front desk",
        "Guarded territory",
        "Conference hall",
        "Organization of events",
        "Parking charge",
        "Parking free",
        "Accommodation with animals",
        "Children's beds in the room",
        "Room cleaning",
        "Nanny"
      ],
      ["English", "Russian", "Ukrainian"],
      [
        "Wi-Fi in the territory",
        "Wi-Fi in the rooms",
        "Wi-Fi in the restaurant",
        "Wi-Fi in the main building"
      ]
    ],
    filterOfServices: {
      accommodationFilterChecked: [
        "Cottage",
        "Bungalow",
        "Main Building",
        "Villa",
        "Tent"
      ],
      buttonLabelClose: "Close",
      distanceFromKiev: {
        listItemText: "DISTANCE FROM KIEV",
        distances: [
          {
            href: "#simple-list",
            value: "up to 5 km"
          },
          {
            href: "#simple-list",
            value: "from 5 to 30 km"
          },
          {
            href: "#simple-list",
            value: "from 30 to 60 km"
          },
          {
            href: "#simple-list",
            value: "from 100 km"
          },
          {
            href: "#simple-list",
            value: "more than 100 km"
          }
        ]
      },
      regionalAreas: {
        listItemText: "REGIONAL AREAS",
        regions: [
          {
            href: "#simple-list",
            name: "Бориспольский"
          },
          {
            href: "#simple-list",
            name: "Бородянский"
          },
          {
            href: "#simple-list",
            name: "Броварской"
          },
          {
            href: "#simple-list",
            name: "Васильковский"
          },
          {
            href: "#simple-list",
            name: "Вышгородский"
          },
          {
            href: "#simple-list",
            name: "Киево-Святошинский"
          },
          {
            href: "#simple-list",
            name: "Обуховский"
          }
        ]
      },
      towns: {
        listItemText: "KIEV REGION",
        cities: [
          {
            href: "/hotels-bila-tserkva",
            name: "Bila Tserkva"
          },
          {
            href: "#simple-list",
            name: "Белогородка"
          },
          {
            href: "#simple-list",
            name: "Борисполь +10 км"
          },
          {
            href: "#simple-list",
            name: "Боярка"
          },
          {
            href: "#simple-list",
            name: "Бровары"
          },
          {
            href: "#simple-list",
            name: "Буча"
          },
          {
            href: "#simple-list",
            name: "Васильков"
          },
          {
            href: "#simple-list",
            name: "Вишневое +10 км"
          },
          {
            href: "#simple-list",
            name: "Ворзель"
          },
          {
            href: "#simple-list",
            name: "Вышгород +10 км"
          },
          {
            href: "#simple-list",
            name: "Гатное"
          },
          {
            href: "#simple-list",
            name: "Глеваха"
          },
          {
            href: "#simple-list",
            name: "Гнедин"
          },
          {
            href: "#simple-list",
            name: "Гора"
          },
          {
            href: "#simple-list",
            name: "Гостомель"
          },
          {
            href: "#simple-list",
            name: "Зазимье"
          },
          {
            href: "#simple-list",
            name: "Ирпень +10 км"
          },
          {
            href: "#simple-list",
            name: "Киев"
          },
          {
            href: "#simple-list",
            name: "Коцюбинское"
          },
          {
            href: "#simple-list",
            name: "Крюковщина"
          },
          {
            href: "#simple-list",
            name: "Макаров"
          },
          {
            href: "#simple-list",
            name: "Мила"
          },
          {
            href: "#simple-list",
            name: "Немешаево"
          },
          {
            href: "#simple-list",
            name: "Новые Петровцы"
          },
          {
            href: "#simple-list",
            name: "Обухов"
          },
          {
            href: "#simple-list",
            name: "Петропавловская Борщаговка"
          },
          {
            href: "#simple-list",
            name: "Подгорцы"
          },
          {
            href: "#simple-list",
            name: "Святопетровское"
          },
          {
            href: "#simple-list",
            name: "Софиевская Борщаговка"
          },
          {
            href: "#simple-list",
            name: "Счастливое"
          },
          {
            href: "#simple-list",
            name: "Тарасовка"
          },
          {
            href: "#simple-list",
            name: "Требухов"
          },
          {
            href: "#simple-list",
            name: "Украинка"
          },
          {
            href: "#simple-list",
            name: "Ходосовка"
          },
          {
            href: "#simple-list",
            name: "Хотов"
          },
          {
            href: "#simple-list",
            name: "Чабаны"
          },
          {
            href: "#simple-list",
            name: "Чайки"
          },
          {
            href: "#simple-list",
            name: "Ясногородка"
          }
        ]
      },
      typeOfAccommodation: {
        formLabel: "Type of accommodation",
        formControlLabelCottage: "Cottages",
        formControlLabelBungalow: "Bungalow",
        formControlLabelMainBuilding: "Main building",
        formControlLabelVilla: "Villa",
        formControlLabelTent: "Tent"
      },
      destinationSlidder: {
        formLabel: "Distance from city",
        formLabelKm: "km"
      },
      facilitiesHotels: {
        formLabel: "Hotel facilities",
        formControlLabelWiFi: "Wi-Fi on site",
        formControlLabelPool: "Pool",
        formControlLabelGym: "Gym",
        formControlLabelFish: "Fishing",
        formControlLabelHorse: "Horseback riding",
        formControlLabelSpa: "SPA area"
      },
      facilitiesRoom: {
        formLabel: "Room facilities",
        formControlLabelWiFi: "Wi-Fi in the room",
        formControlLabelAir: "Air conditioning",
        formControlLabelFridge: "Fridge",
        formControlLabelTv: "TV",
        formControlLabelSafe: "Safe"
      }
    },
    priceFilter: {
      name: "Price",
      anyPrice: "Any Price",
      underPrice: "Under",
      currency: "UAH"
    }
  },
  Sorting: {
    byPopularity: "By popularity",
    cheapFirst: "Cheap first",
    expensiveFirst: "Expensive first",
    byReviewRank: "By reviews rank",
    discountFirst: "Discounts first"
  }
};
