const filtersStyle = theme => ({
  paperStyle: {
    minWidth: "324px",
    padding: "8px",
    marginTop: "4px",
    borderRadius: "8px"
  },
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex"
  },
  subHeader: {
    alignItems: "center",
    flex: "0 0 auto",
    display: "flex",
    padding: "0 16px"
  },
  button: {
    textTransform: "none",
    marginRight: "8px"
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&:active": {
      boxShadow: "inherit"
    }
  },
  chipRoot: {
    marginRight: theme.spacing(0.5),
    height: "36px",
    backgroundColor: "rgb(58, 177, 235)",
    fontSize: "0.875rem",
    borderRadius: theme.spacing(0.5)
  },
  chipLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  chipRootButton: {
    marginRight: theme.spacing(0.5),
    height: "36px",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    },
    "&:active": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    },
    "&:focus": {
      backgroundColor: "#ffffff"
    },
    fontSize: "0.875rem",
    borderRadius: "4px",
    color: "#000000",
    border: "1px solid rgba(0, 0, 0, 0.23)"
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  divForList: {
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "240px",
    flexDirection: "column",
    display: "flex",
    height: "100%"
  },
  filterOfServ: {
    paddingBottom: theme.spacing(2)
  },
  forLegend: {
    paddingTop: theme.spacing(2),
    color: "rgb(58, 177, 235)",
    fontSize: "1.2rem"
  }
});

export default filtersStyle;
