import React from "react";
import PropTypes from "prop-types";
import { ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/HotelList/servicesStyle";

const useStyles = makeStyles(styles);

// make multi language String with TEXT and accommodations
export const TypeOfAccomodation = props => {
  const { typeOfAccommod, typeOfAccommodTranslate } = props;
  const classes = useStyles();
  let accommodations = "";
  typeOfAccommod.forEach((accomod, id) => {
    if (accomod.selected === true) {
      accommodations = accommodations
        .concat(typeOfAccommodTranslate[id])
        .concat(", ");
    }
  });
  //delete , and space
  accommodations = accommodations.substr(0, accommodations.length - 2);
  return (
    <ListItemText
      className={classes.itemText}
      inset={true}
      primary={
        <Typography type="body2" className={classes.itemTextTyp}>
          {accommodations}
        </Typography>
      }
    />
  );
};
TypeOfAccomodation.propTypes = {
  typeOfAccommod: PropTypes.array.isRequired,
  typeOfAccommodTranslate: PropTypes.array.isRequired,
};
