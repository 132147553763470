import React from "react";

import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  MenuItem,
  Popper,
  Typography
} from "@material-ui/core";
import styles from "../../_jss/MainPage/autocompleteStyle";
import { config } from "../../_config";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  return (
    <TextField
      style={{ margin: 8, width: 340 }}
      margin="normal"
      variant="outlined"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  classes
}) {
  const isHighlighted = highlightedIndex === index;

  //we have some bug indexOf not a function
  //console.log('isSelected', selectedItem)
  //const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
  //console.log('suggestion', suggestion);
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: selectedItem ? 500 : 400
      }}
    >
      <img
        src={config.urlImgHotelThumbnail + suggestion.img}
        style={{ width: 30, height: 30, paddingRight: 4 }}
        alt={suggestion.label}
      />
      <Typography variant="body2" className={classes.searchTypoName}>
        {suggestion.label},
      </Typography>
      <Typography variant="body2" className={classes.searchTypoCity}>
        {suggestion.city},
      </Typography>
      <Typography variant="body2">{suggestion.region}</Typography>
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  classes: PropTypes.object,
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({
    label: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    img: PropTypes.string
  }).isRequired
};

function getSuggestions(suggestions, value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  //return only suggestions: first 5 result and start with enter Value
  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        //check first 5 result AND have suggestion input value, if ok then we return array with suggestion
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
        if (keep) {
          count += 1;
        }
        return keep;
      });
}

let popperNode;

const Autocomplete = props => {
  const { classes, strings, hotelnames, handleAutocompleteChange } = props;
  return (
    <>
      <Downshift
        id="downshift-popper"
        onChange={handleAutocompleteChange}
        itemToString={item =>
          item ? item.label + ", " + item.city + ", " + item.region : ""
        }
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem
        }) => (
          <div className={classes.container}>
            {renderInput({
              classes,
              label: strings.textFieldLabel,
              InputProps: getInputProps({
                placeholder: strings.textFieldPlaceholder
              }),
              ref: node => {
                popperNode = node;
              }
            })}
            <Popper
              open={isOpen}
              anchorEl={popperNode}
              placement="bottom-start"
            >
              <div
                {...(isOpen
                  ? getMenuProps({}, { suppressRefError: true })
                  : {})}
              >
                <Paper square>
                  {getSuggestions(hotelnames, inputValue).map(
                    (suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion
                        }),
                        highlightedIndex,
                        selectedItem,
                        classes
                      })
                  )}
                </Paper>
              </div>
            </Popper>
          </div>
        )}
      </Downshift>
    </>
  );
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  hotelnames: PropTypes.array.isRequired,
  handleAutocompleteChange: PropTypes.func.isRequired
};

export default withStyles(styles)(Autocomplete);
