import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import { Done, HotTub, Info } from "@material-ui/icons";
import styles from "../../_jss/HotelPage/servicesStyle";
const useStyles = makeStyles(styles);

const serviceIcon = [<HotTub />, <Info />];

const ListServiceRoom = props => {
  const { strings, indexService, service, isVertCart } = props;
  const classes = useStyles();
  return (
    <List dense={false}>
      <ListItem>
        <ListItemIcon className={classes.listItemIconRoot}>
          {serviceIcon[indexService]}
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextServiceName}
          primary={strings.name[indexService]}
        />
      </ListItem>
      {service.items.map((item, indexItem) => (
        <React.Fragment key={indexItem}>
          {item.selected && (
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconRoot}>
                <Done fontSize="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={strings.services[indexService][indexItem]}
                className={classes.listItemText}
                classes={isVertCart && { primary: classes.listItemTextPrimary }}
              />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

ListServiceRoom.propTypes = {
  service: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  indexService: PropTypes.number.isRequired,
  isVertCart: PropTypes.bool
};

export default ListServiceRoom;
