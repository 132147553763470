const mainPageStyle = theme => ({
  mainPage: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      //maxWidth: 966,
      maxWidth: 1024,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  searchGrid: {
    margin: "0 auto",
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  button: {
    margin: theme.spacing(0.5),
    width: 90,
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      width: 200,
      margin: theme.spacing(1)
    }
  },
  filterDesktop: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  filterDesktopMap: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  filterMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  drawer: {
    width: 320,
    flexShrink: 0
  },
  drawerPaper: {
    width: 320
  },
  filterOfServ: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  sortingButton: {
    textTransform: "none"
  },
  sortingButtonTurnOn: {
    color: "rgb(2, 108, 160)",
    fontWeight: "700",
    textTransform: "none"
  },
  sorting: {
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  sortingMap: {
    display: "none"
  }
});

export default mainPageStyle;
