import React from "react";
import PropTypes from "prop-types";
import { Breadcrumbs, Typography, Link, SvgIcon } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../_jss/HotelPage/breadCrumbSection";

const useStyles = makeStyles(styles);

export const BreadCrumbsSection = props => {
  const { hotel, handleClickBread, currentLanguage, strings } = props;
  const classes = useStyles();

  return (
    <Breadcrumbs
      className={classes.breadCrumbs}
      classes={{ separator: classes.separatorRoot }}
      separator={
        <NavigateNextIcon className={classes.separator} fontSize="small" />
      }
      aria-label="Breadcrumb"
    >
      <Link
        color="primary"
        onClick={handleClickBread}
        className={classes.breadLink}
      >
        <SvgIcon className={classes.iconBreadCrumb} color="primary">
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
        {strings.breadCrumbsMain}
      </Link>
      <Link color="primary" href="/" onClick={handleClickBread}>
        {currentLanguage === "en" && hotel.city[0].value}
        {currentLanguage === "uk" && hotel.city[1].value}
        {currentLanguage === "ru" && hotel.city[2].value}
      </Link>
      <Typography color="textPrimary">
        {hotel.hotelname[currentLanguage]}
      </Typography>
      ;
    </Breadcrumbs>
  );
};

BreadCrumbsSection.propTypes = {
  hotel: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleClickBread: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};
