//340px
import React from "react";
import PropTypes from "prop-types";
import {
  ClickAwayListener,
  Checkbox,
  Chip,
  Popper,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown, Close } from "@material-ui/icons";
import styles from "../../../_jss/Filters/filtersStyle";

const useStyles = makeStyles(styles);

function FilterChip(props) {
  const {
    classes,
    handleClick,
    handleDelete,
    isFiltered,
    serviceName,
    anchorEl,
    open
  } = props;
  const onDelete = e => {
    handleDelete(e);
    open && handleClick(e);
  };
  if (!isFiltered)
    return (
      <Chip
        ref={anchorEl}
        color="primary"
        label={
          <>
            {serviceName} <ArrowDropDown />
          </>
        }
        onClick={handleClick}
        classes={{ root: classes.chipRootButton, label: classes.chipLabel }}
      />
    );
  else
    return (
      <Chip
        ref={anchorEl}
        color="primary"
        onDelete={onDelete}
        label={serviceName}
        onClick={handleClick}
        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
      />
    );
}

FilterChip.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired
};

export const FilterServiceDesktop = props => {
  const {
    items,
    handleChangeFilter,
    //handleTurnOnAll,
    serviceName,
    serviceId,
    itemsTranslate,
    handleDeleteFilter,
    nameOfFilter
  } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  //boolean true is some item selected
  const isFiltered = items.some(item => item.selected === true);
  return (
    <>
      <FilterChip
        handleClick={handleClick}
        handleDelete={handleDeleteFilter(serviceId, nameOfFilter)}
        classes={classes}
        isFiltered={isFiltered}
        anchorEl={anchorRef}
        open={open}
        serviceName={serviceName}
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          modifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent"
            },
            hide: {
              enabled: false
            }
          }}
          placement="bottom-start"
          transition
        >
          <Paper square className={classes.paperStyle}>
            <div className={classes.header}>
              <Typography variant="body1" style={{ padding: "8px" }}>
                {serviceName}
              </Typography>
              <IconButton
                onClick={handleClick}
                className={classes.button}
                aria-label="Close"
              >
                <Close />
              </IconButton>
            </div>
            <div className={classes.divForList}>
              <List>
                {items.map((item, id) => (
                  <React.Fragment key={id}>
                    {item.isFilter && (
                      <ListItem className={classes.listItem}>
                        <Checkbox
                          checked={item.selected}
                          onChange={handleChangeFilter(serviceId, id)}
                          value={id}
                          color="primary"
                          inputProps={{
                            "aria-label": `${itemsTranslate[id]}`
                          }}
                        />
                        <ListItemText primary={itemsTranslate[id]} />
                      </ListItem>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </div>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

FilterServiceDesktop.propTypes = {
  handleChangeFilter: PropTypes.func.isRequired,
  handleTurnOnAll: PropTypes.func.isRequired,
  handleDeleteFilter: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  itemsTranslate: PropTypes.array.isRequired,
  serviceId: PropTypes.number.isRequired,
  nameOfFilter: PropTypes.string.isRequired
};
