const iconButton = {
  width: 24,
  height: 24,
  padding: 8,
  paddingLeft: 0
};
const icon = {
  width: 16,
  height: 16
};

export { iconButton, icon };
