import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItemText, ListItem, ListItemIcon } from "@material-ui/core";
import {
  Done,
  Info,
  Pool,
  Chat,
  Wifi,
  LocalFlorist,
  LocalDining,
  GolfCourse
} from "@material-ui/icons";

import styles from "../../_jss/HotelPage/servicesStyle";
import translate from "../../HOC/translate";

const useStyles = makeStyles(styles);

function checkIfServiceItemsExist(service) {
  return (
    service.items.filter(item => {
      return item.selected === true;
    }).length > 0
  );
}
const serviceIcon = [
  <GolfCourse />,
  <Pool />,
  <LocalFlorist />,
  <LocalDining />,
  <Info />,
  <Chat />,
  <Wifi />
];

const Services = props => {
  const { services, strings } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.divCount}>
        {services.map((service, indexService) => (
          <React.Fragment key={indexService}>
            {checkIfServiceItemsExist(service) && (
              <div key={indexService} style={{ display: "flex" }}>
                <List key={indexService} dense={false}>
                  <ListItem>
                    <ListItemIcon className={classes.listItemIconRoot}>
                      {serviceIcon[indexService]}
                    </ListItemIcon>
                    <ListItemText
                      primary={strings.name[indexService]}
                      className={classes.listItemTextServiceName}
                    />
                  </ListItem>
                  {service.items.map((item, indexItem) => (
                    <React.Fragment key={item.name}>
                      {item.selected && (
                        <ListItem key={item.name} className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIconRoot}>
                            <Done fontSize="inherit" />
                          </ListItemIcon>
                          <ListItemText
                            primary={strings.services[indexService][indexItem]}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

Services.propTypes = {
  services: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired
};

const ServicesCompose = compose(translate("Services"))(Services);

export { ServicesCompose as Services };
