import { initialStateFilters } from "./initalStateFilters";
const FILTERS_CHANGE = "zamistom.kiev.ua/filters/FILTERS_CHANGE";
const CHANGE_ADDITIONAL_FILTER =
  "zamistom.kiev.ua/filters/CHANGE_ADDITIONAL_FILTER";
const DELETE_ADDITIONAL_FILTERS =
  "zamistom.kiev.ua/filters/DELETE_ADDITIONAL_FILTERS";
const DELETE_FILTER = "zamistom.kiev.ua/filters/DELETE_FILTER";
const PREPARE_FILTERS = "zamistom.kiev.ua/filters/PREPARE_FILTERS";
const RESET_ALL_FILTERS = "zamistom.kiev.ua/filters/RESET_ALL_FILTERS";
export const changeFilters = (serviceId, itemId) => ({
  type: FILTERS_CHANGE,
  serviceId,
  itemId
});
export const changeAdditionalFilters = (name, value) => ({
  type: CHANGE_ADDITIONAL_FILTER,
  name: name,
  value: value
});
export const deleteAdditionalFilters = filter_name => ({
  type: DELETE_ADDITIONAL_FILTERS,
  filter_name: filter_name
});
export const deleteFilter = (serviceId, nameOfFilter) => ({
  type: DELETE_FILTER,
  serviceId: serviceId,
  nameOfFilter: nameOfFilter
});
export const prepareFilters = (priceMin, priceMax) => ({
  type: PREPARE_FILTERS,
  priceMin: priceMin,
  priceMax: priceMax
});
export const resetAllFilters = () => ({ type: RESET_ALL_FILTERS });

export const filtersReducer = (state = initialStateFilters, action) => {
  switch (action.type) {
    case RESET_ALL_FILTERS:
      return {
        ...state,
        filters: initialStateFilters.filters,
        additionalFilters: {
          ...state.additionalFilters,
          price: state.additionalFilters.priceMax
        }
      };
    case DELETE_FILTER:
      // eslint-disable-next-line no-case-declarations
      const filtersDelete = state.filters.map((filter, serviceIndex) => {
        let items;
        if (serviceIndex === action.serviceId) {
          items = filter.items.map(item => {
            return { ...item, selected: false };
          });
          return { ...filter, items: items };
        }
        return filter;
      });
      return { ...state, filters: filtersDelete };
    case PREPARE_FILTERS:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          price: action.priceMax,
          priceMin: action.priceMin,
          priceMax: action.priceMax
        }
      };
    case DELETE_ADDITIONAL_FILTERS:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          [action.filter_name]: state.additionalFilters.priceMax
        }
      };
    case CHANGE_ADDITIONAL_FILTER:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          [action.name]: action.value
        }
      };
    case FILTERS_CHANGE:
      // eslint-disable-next-line no-case-declarations
      const filtersChange = state.filters.map((filter, serviceIndex) => {
        let items;
        if (serviceIndex === action.serviceId) {
          items = filter.items.map((item, itemIndex) => {
            if (itemIndex === action.itemId) {
              return { ...item, selected: !item.selected };
            }
            return item;
          });
          return { ...filter, items: items };
        }
        return filter;
      });
      return { ...state, filters: filtersChange };
    default:
      return state;
  }
};
