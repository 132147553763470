import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import en from "../i18n/en";
import ru from "../i18n/ru";
import uk from "../i18n/uk";
import { getLanguage } from "../_reducers/selectors";

const languages = { en, ru, uk };

export default function translate(key) {
  return Component => {
    const stateToProps = state => ({
      currentLanguage: getLanguage(state)
    });

    class TranslationComponent extends React.Component {
      render() {
        const strings = languages[this.props.currentLanguage][key];
        const merged = {
          ...this.props.strings,
          ...strings
        };
        if (strings) {
          return (
            <Component
              {...this.props}
              strings={merged}
              currentLanguage={this.props.currentLanguage}
            />
          );
        }

        return (
          <Component
            {...this.props}
            currentLanguage={this.props.currentLanguage}
          />
        );
      }
    }

    TranslationComponent.propTypes = {
      strings: PropTypes.object,
      currentLanguage: PropTypes.string
    };

    return connect(stateToProps)(TranslationComponent);
  };
}
