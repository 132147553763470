import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import translate from "../../HOC/translate";
import { Sorting } from "./Sorting";
import { getTurnOnSorting, getSortings } from "../../_reducers/selectors";
import { changeTypeSorting } from "../../_reducers/ducks-sortings";

const SortingContainer = props => {
  const { stateShowMap, sortingTrue, changeTypeSorting, strings } = props;
  const onClick = sorting => () => {
    changeTypeSorting(sorting);
  };
  //console.log();
  return (
    <Sorting
      stateShowMap={stateShowMap}
      onClick={onClick}
      sortingTrue={sortingTrue}
      strings={strings}
    />
  );
};

SortingContainer.propTypes = {
  stateShowMap: PropTypes.bool.isRequired,
  sortingTrue: PropTypes.string.isRequired,
  changeTypeSorting: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sortingTrue: getTurnOnSorting(state),
  sortings: getSortings(state)
});

const mapDispatchToProps = {
  changeTypeSorting
};

const SortingContainerCompose = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate("Sorting")
)(SortingContainer);
export { SortingContainerCompose as SortingContainer };
