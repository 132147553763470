import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
//import { createLogger } from "redux-logger";
import { combineReducers } from "redux";

import { getHotelsReducer } from "./ducks-get-hotels";
import { languageReducer } from "./ducks-language";
import { filtersReducer } from "./ducks-filters";
import { findHotelReducer } from "./ducks-hotelpage";
import { sortingReducer } from "./ducks-sortings";

const rootReducer = combineReducers({
  languageReducer,
  getHotelsReducer,
  filtersReducer,
  findHotelReducer,
  sortingReducer
});

//const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
  //applyMiddleware(thunkMiddleware, loggerMiddleware)
);
