import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { MuiThemeProvider } from "@material-ui/core/styles";
//import { StylesProvider, jssPreset } from '@material-ui/styles';
//import { create } from 'jss';
import { NavBar } from "./NavigationBar";
import { history } from "./_helpers";
import { MainPage } from "./MainPage/MainPage";
import { myTheme } from "./_helpers";
import MetaHead from "./MetaHead";
//import {RoutesCity} from './_components'
import { HotelPage } from "./HotelPage/HotelPage";

// const jss = create({
//   ...jssPreset(),
//   // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
//   insertionPoint: document.getElementById('jss-insertion-point'),
// });

ReactGA.initialize("UA-141156101-1");
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

class App extends Component {
  render() {
    //const { classes } = this.props;
    return (
      <Router history={history}>
        {/* <StylesProvider jss={jss}> */}
        <MetaHead />
        <MuiThemeProvider theme={myTheme}>
          <>
            <NavBar />
            <Route
              exact
              path="/"
              render={props => (
                <MainPage
                  {...props}
                  canonical="https://zamistom.kiev.ua"
                  lang="uk"
                />
              )}
            />
            <Route
              exact
              strict
              path="/ru"
              render={props => (
                <MainPage
                  {...props}
                  canonical="https://zamistom.kiev.ua/ru"
                  lang="ru"
                />
              )}
            />
            <Route
              exact
              strict
              path="/en"
              render={props => (
                <MainPage
                  {...props}
                  canonical="https://zamistom.kiev.ua/en"
                  lang="en"
                />
              )}
            />
            <Route
              exact
              strict
              path="/ua"
              render={props => (
                <MainPage
                  {...props}
                  canonical="https://zamistom.kiev.ua/ua"
                  lang="uk"
                />
              )}
            />
            <Route
              path={[
                "/ua/hotel/:searchQuery",
                "/en/hotel/:searchQuery",
                "/ru/hotel/:searchQuery"
              ]}
              render={props => <HotelPage {...props} />}
            />
            {/* i need make 
                  <Route path="/region" component={RegionPage} />
                  <Route path="/city" component={CityPage} />
                  <Route path="/forfamily" component={FamilyPage}
                  */}
            {/* <RoutesCity/> */}
          </>
        </MuiThemeProvider>
        {/* </StylesProvider> */}
      </Router>
    );
  }
}

export default App;
