const listItemIconRoot = {
  minWidth: 0
};
const listItemIconRoomInfo = {
  padding: "0 8px",
  minWidth: 0
};
const listItem = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 8
};
const listItemTextServiceName = {
  padding: "0 8px"
};
const listItemText = {
  padding: "0 8px",
  marginTop: 0,
  marginBottom: 0
};

export {
  listItemIconRoot,
  listItem,
  listItemTextServiceName,
  listItemText,
  listItemIconRoomInfo
};
