import React, { Component } from "react";
//import "./map.css";
import PropTypes from "prop-types";
import { compose } from "redux";
import {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl
} from "react-map-gl";
import ReactMapGL from "react-map-gl";
import { Button, Grid } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import { HotelPin, HotelInfo, HotelInfoOnMouseOver } from "./";
import translate from "../../HOC/translate";
import styles from "../../_jss/MapBox/mapBoxStyle";
const TOKEN =
  "pk.eyJ1IjoiYW5yeTgiLCJhIjoiY2p3YzVmcGNyMG1ocTQ5bHp5NjEzYmVsZCJ9.vmNvydynZ7z4kmDW_eUiVg"; // Set your mapbox token here

const fullscreenControlStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px"
};

const navStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px"
};

class MapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 50.4501,
        longitude: 30.5234,
        zoom: 7,
        bearing: 0,
        pitch: 0,
        width: "100%",
        height: "100%"
      },
      popupInfo: null,
      popupOnMouseOver: null
    };
  }

  _updateViewport = viewport => {
    this.setState({ viewport });
  };

  closePopUpInfo = () => {
    this.setState({ popupInfo: null });
  };
  _renderHotelsMarker = (hotel, index) => {
    let hotelRoomMinPrice = 0;
    hotel.roomstype.forEach(room => {
      if (room.price < hotelRoomMinPrice || hotelRoomMinPrice === 0)
        hotelRoomMinPrice = room.price;
    });
    return (
      <Marker
        key={`marker-${index}`}
        longitude={hotel.longitude}
        latitude={hotel.latitude}
      >
        <HotelPin
          size={20}
          hotelRoomMinPrice={hotelRoomMinPrice}
          onClick={event => {
            event.preventDefault();
            this.setState({ popupInfo: hotel, popupOnMouseOver: null });
          }}
          onMouseOver={event => {
            event.preventDefault();
            this.setState({ popupOnMouseOver: hotel });
          }}
          onMouseLeave={event => {
            event.preventDefault();
            this.setState({ popupOnMouseOver: null });
          }}
        />
      </Marker>
    );
  };

  _renderPopupOnMouseOver() {
    const { popupOnMouseOver } = this.state;

    return (
      popupOnMouseOver && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupOnMouseOver.longitude}
          latitude={popupOnMouseOver.latitude}
          closeOnClick={true}
          offsetLeft={0}
          offsetTop={0}
          onClose={() => this.setState({ popupOnMouseOver: null })}
        >
          <HotelInfoOnMouseOver
            currentLanguage={this.props.currentLanguage}
            hotel={popupOnMouseOver}
          />
        </Popup>
      )
    );
  }

  _renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <HotelInfo
            strings={this.props.strings}
            currentLanguage={this.props.currentLanguage}
            hotel={popupInfo}
            closePopUpInfo={this.closePopUpInfo}
          />
        </Popup>
      )
    );
  }

  render() {
    const { viewport } = this.state;
    const {
      hotels,
      classes,
      strings,
      handleClickFilter,
      handleCloseMap
    } = this.props;
    // console.log(hotels);
    return (
      <Grid item className={classes.searchGrid}>
        <div key="mapbox" style={{ height: "90vh", width: "100%" }}>
          <ReactMapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/mapbox/streets-v10"
            onViewportChange={this._updateViewport}
            mapboxApiAccessToken={TOKEN}
          >
            {hotels.map(this._renderHotelsMarker)}
            {this._renderPopup()}
            {this._renderPopupOnMouseOver()}
            <div className="fullscreen" style={fullscreenControlStyle}>
              <FullscreenControl />
            </div>
            <div className="nav" style={navStyle}>
              <NavigationControl onViewportChange={this._updateViewport} />
            </div>
            <div className={classes.buttonCloseMap}>
              <Button
                className={classes.buttonFilter}
                onClick={handleClickFilter}
                color="primary"
                variant="contained"
              >
                {strings.buttonLabelFilter}
              </Button>
              <Button
                className={classes.button}
                onClick={handleCloseMap}
                color="primary"
                variant="contained"
              >
                {strings.buttonLabelClose}
              </Button>
            </div>
          </ReactMapGL>
        </div>
      </Grid>
    );
  }
}

MapBox.propTypes = {
  hotels: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  handleClickFilter: PropTypes.func.isRequired,
  handleCloseMap: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

const MapBoxCompose = compose(
  withStyles(styles),
  translate("MapBox")
)(MapBox);
export { MapBoxCompose as MapBox };
