const breadCrumbSection = theme => ({
  breadCrumbs: {
    paddingTop: theme.spacing(2)
  },
  separatorRoot: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 0
    }
  },
  separator: {
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  },
  breadLink: {
    display: "flex",
    cursor: "pointer"
  },
  iconBreadCrumb: {
    marginRight: theme.spacing(0.5),
    width: 24
  }
});

export default breadCrumbSection;
