//import { authHeader, authHeaderForImage } from '../_helpers';
import { config } from "../_config";
import axios from "axios";

export const hotelsApi = {
  getHotels,
  getAutocomplete,
  findById,
  findHotelbyName
};

function findById(hotel_id) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ hotel_id })
  };
  return axios(`${config.apiUrl}/hotels/findById`, requestOptions);
}

function findHotelbyName(hotelname) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ hotelname })
  };
  return axios(`${config.apiUrl}/hotels/findByName`, requestOptions);
}

function getHotels() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };
  return axios(`${config.apiUrl}/hotels/all`, requestOptions);
}

function getAutocomplete() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };
  return axios(`${config.apiUrl}/hotels/autocomplete`, requestOptions);
}
