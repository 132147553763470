import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
  Grid,
  Card,
  CardContent
} from "@material-ui/core";
import {
  LocalDining,
  LocalHotel,
  EventNote,
  MonetizationOn
} from "@material-ui/icons";

import CardHeaderMultiLang from "./CardHeaderMultiLang";
import RoomGallery from "./RoomGallery";
import ListServiceRoom from "./ListServiceRoom";
import translate from "../../HOC/translate";
import styles from "../../_jss/HotelPage/servicesStyle";
const useStyles = makeStyles(styles);

const mainInfoRoomIcon = [<EventNote />, <LocalHotel />, <LocalDining />, null];

function MulitLangData(props) {
  const { data, currentLanguage } = props;
  switch (currentLanguage) {
    case "en":
      return data[0].value;
    case "uk":
      return data[1].value;
    case "ru":
      return data[2].value;
    default:
      return data[0].value;
  }
}

const RoomInfo = props => {
  const {
    data,
    currentLanguage,
    classes,
    icon,
    isPrice,
    isDescription
  } = props;
  return (
    <ListItem>
      {icon && (
        <ListItemIcon className={classes.listItemIconRoomInfo}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          isPrice ? (
            data
          ) : (
            <MulitLangData data={data} currentLanguage={currentLanguage} />
          )
        }
        className={classes.listitemText}
        classes={isDescription && { primary: classes.listItemTextPrimary }}
      />
    </ListItem>
  );
};

RoomInfo.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired
  ]),
  currentLanguage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.any]),
  isPrice: PropTypes.bool,
  isDescription: PropTypes.bool
};

const RoomsListVertCart = props => {
  const { roomstype, currentLanguage, strings } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      {roomstype.map((room, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Card className={classes.card}>
            <CardHeaderMultiLang
              currentLanguage={currentLanguage}
              roomname={room.roomname}
              size={room.size}
              personcount={room.personcount}
            />
            <RoomGallery
              imgs={room.imgs}
              roomname={room.roomname[0].value}
              strings={strings}
            />
            <CardContent style={{ padding: 0 }}>
              <List dense={false}>
                {room.price && (
                  <RoomInfo
                    data={
                      strings.roomPriceText1 +
                      room.price +
                      strings.roomPriceText2
                    }
                    currentLanguage={currentLanguage}
                    icon={<MonetizationOn />}
                    classes={classes}
                    isPrice={true}
                  />
                )}
                {room.food[0].value && (
                  <RoomInfo
                    data={room.food}
                    currentLanguage={currentLanguage}
                    icon={mainInfoRoomIcon[2]}
                    classes={classes}
                  />
                )}
                {room.beds[0].value && (
                  <RoomInfo
                    data={room.beds}
                    currentLanguage={currentLanguage}
                    icon={mainInfoRoomIcon[1]}
                    classes={classes}
                  />
                )}
                {room.rules[0].value && (
                  <RoomInfo
                    data={room.rules}
                    currentLanguage={currentLanguage}
                    icon={mainInfoRoomIcon[0]}
                    classes={classes}
                  />
                )}
                <RoomInfo
                  data={room.description}
                  currentLanguage={currentLanguage}
                  icon={mainInfoRoomIcon[4]}
                  classes={classes}
                  isDescription={true}
                />
              </List>
              {room.services.map((service, indexService) => (
                <React.Fragment key={indexService}>
                  <ListServiceRoom
                    strings={strings}
                    indexService={indexService}
                    service={service}
                    isVertCart={true}
                  />
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

RoomsListVertCart.propTypes = {
  roomstype: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

const RoomsListVertCartCompose = compose(translate("RoomsType"))(
  RoomsListVertCart
);

export { RoomsListVertCartCompose as RoomsListVertCart };
