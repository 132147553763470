import React from "react";
import PropTypes from "prop-types";
import { Button, Typography, Grid } from "@material-ui/core/";

export const NoResults = props => {
  const { handleResetAllFilters, noResult, buttonResetAllFitlersText } = props;
  return (
    <Grid
      container
      direction="column"
      style={{ marginBottom: "24px" }}
      spacing={4}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h6">{noResult}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleResetAllFilters}
          variant="contained"
          color="primary"
        >
          {buttonResetAllFitlersText}
        </Button>
      </Grid>
    </Grid>
  );
};

NoResults.propTypes = {
  buttonResetAllFitlersText: PropTypes.string.isRequired,
  noResult: PropTypes.string.isRequired,
  handleResetAllFilters: PropTypes.func.isRequired
};
