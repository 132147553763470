export const config = {
  //dev
 // apiUrl: "http://localhost:3001",
 // urlImgHotel: "http://localhost:3001/hotels/",
 // urlImgHotelCard: "http://localhost:3001/hotels/320/140/",
 // urlImgHotelMap: "http://localhost:3001/hotels/260/120/",
 // urlImgHotelThumbnail: "http://localhost:3001/hotels/100/67/",
 // urlImgRoom: "http://localhost:3001/rooms/",
 // urlImgRoomCard: "http://localhost:3001/rooms/480/270/",
 // urlImgRoomThumbnail: "http://localhost:3001/rooms/100/67/"
  //prod
  // apiUrl: 'http://62.80.184.155:3001',
  // urlImgHotel : 'http://62.80.184.155:3001/hotels/',
  // urlImgRoom : 'http://62.80.184.155:3001/rooms/',
  //prod https
   apiUrl: 'https://api.zamistom.kiev.ua',
   urlImgHotel : 'https://api.zamistom.kiev.ua/hotels/',
   urlImgHotelCard : 'https://api.zamistom.kiev.ua/hotels/320/140/',
   urlImgHotelMap : 'https://api.zamistom.kiev.ua/hotels/260/120/',
   urlImgHotelThumbnail : 'https://api.zamistom.kiev.ua/hotels/100/67/',
   urlImgRoom : 'https://api.zamistom.kiev.ua/rooms/',
   urlImgRoomCard : 'https://api.zamistom.kiev.ua/rooms/480/270/',
   urlImgRoomThumbnail : 'https://api.zamistom.kiev.ua/rooms/100/67/',
};
