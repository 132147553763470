import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

import translate from "../HOC/translate";

const styles = theme => ({
  textTransformNone: { textTransform: "none" }
});

class UserMenu extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, strings } = this.props;
    return (
      <div>
        <Button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          color="primary"
          className={classes.textTransformNone}
        >
          user.username
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            {strings.personalAccount}
          </MenuItem>
          <MenuItem onClick={this.handleClose}>{strings.orders}</MenuItem>
          <MenuItem onClick={this.handleClose}>{strings.discount}</MenuItem>
          <MenuItem onClick={this.onLogout}>{strings.leave}</MenuItem>
        </Menu>
      </div>
    );
  }
}



const UserMenuCompose = compose(
  withRouter,
  withStyles(styles),
  connect(null),
  translate("UserMenu")
)(UserMenu);
export { UserMenuCompose as UserMenu };
