import React from "react";
import PropTypes from "prop-types";
import Rating from "material-ui-rating";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  Avatar
} from "@material-ui/core";

import styles from "../../../_jss/HotelList/reviewsStyle";

const useStyles = makeStyles(styles);

export const Reviews = props => {
  const { reviews } = props;
  const classes = useStyles();
  const { iconButton, icon } = classes;
  const classesRate = { iconButton, icon };
  return (
    <CardContent classes={{ root: classes.cardContentRoot }}>
      <Paper className={classes.paperReviewWithScroll}>
        {reviews.map((review, indexReview) => (
          <Card key={indexReview} classes={{ root: classes.root }}>
            <CardHeader
              classes={{ root: classes.cardHeaderRoot }}
              avatar={
                <Avatar
                  src={review.profile_photo_url}
                  className={classes.avatar}
                />
              }
              title={review.author_name}
              subheader={review.relative_time_description}
            />
            <CardContent classes={{ root: classes.cardContentRoot }}>
              <Rating
                readOnly
                classes={classesRate}
                value={review.rating}
                max={5}
              />
              {review.text}
            </CardContent>
          </Card>
        ))}
      </Paper>
    </CardContent>
  );
};

Reviews.propTypes = {
  reviews: PropTypes.array.isRequired
};
