import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, Button } from "@material-ui/core";

import { HotelGallery } from "../ListHotels/parts";
import { CardHeaderHotelName } from "./";
import styles from "../../_jss/MapBox/mapBoxStyle";
const useStyles = makeStyles(styles);

export const HotelInfo = props => {
  const { hotel, currentLanguage, strings, closePopUpInfo } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Link
        to={{
          pathname:
            currentLanguage !== "uk"
              ? `${currentLanguage}/hotel/${hotel.hotelname.en.replace(/ /g,"-")}`
              : `ua/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
        }}
        target="_blank"
        className={classes.link}
      >
        <CardHeaderHotelName
          hotelname={hotel.hotelname}
          rating={hotel.rating}
          currentLanguage={currentLanguage}
        />
      </Link>
      <HotelGallery
        imgHotelPathArray={hotel.imgHotelPathArray}
        hotelname={hotel.hotelname.en}
        strings={strings}
        isHotelPage={false}
      />
      <CardActions className={classes.cardAction}>
        <Button
          color="primary"
          className={classes.button}
          component={Link}
          to={{
            pathname:
              currentLanguage !== "uk"
                ? `${currentLanguage}/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
                : `ua/hotel/${hotel.hotelname.en.replace(/ /g, "-")}`
          }}
          target="_blank"
        >
          {strings.buttonDetailsText}
        </Button>
        <Button
          color="primary"
          onClick={closePopUpInfo}
          className={classes.button}
        >
          {strings.buttonCloseCard}
        </Button>
      </CardActions>
    </Card>
  );
};

HotelInfo.propTypes = {
  hotel: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  closePopUpInfo: PropTypes.func.isRequired
};
