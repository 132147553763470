import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress } from "@material-ui/core/";

import { SearchHotelsContainer } from "./Search/SearchHotelsContainer";
//import { PopularDestination } from './PopularDestinations/PopularDestination';
import { ListHotels } from "./ListHotels/ListHotels";
import { BottomPart } from "../BottomPart/BottomPart";
import { SortingContainer } from "./Sorting/SortingContainer";
import { MapBox } from "./MapBox/MapBox";
import { HelmetPart } from "./HelmetPart";

import translate from "../HOC/translate";

import { getHotels } from "../_reducers/ducks-get-hotels";
import { changeLanguage } from "../_reducers/ducks-language";
import { resetAllFilters } from "../_reducers/ducks-filters";
import {
  getAllHotels,
  getHotelsError,
  getHotelsLoaded,
  getHotelsLoading,
  getAllFilters,
  filteredAndSorteredHotel
} from "../_reducers/selectors";
import styles from "../_jss/MainPage/mainPageStyle";
import { Filters } from "./Filters/Filters";

class MainPage extends Component {
  componentDidMount() {
    this.props.filteredHotels.length === 0 && this.props.getHotels();
    this.props.lang !== "uk" && this.props.changeLanguage(this.props.lang);
  }

  state = {
    hotelsPerPage: 18,
    showFilter: false,
    showMap: false
  };

  handleClickFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  handleOpenMap = () => {
    this.setState({ showMap: true, showFilter: true });
  };
  handleCloseMap = () => {
    this.setState({ showMap: false, showFilter: false });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  handleChangeHotelsPerPage = () => {
    this.setState({ hotelsPerPage: this.state.hotelsPerPage + 9 });
  };

  handleResetAllFilters = () => {
    this.props.resetAllFilters();
  };
  render() {
    const { strings, classes, canonical } = this.props;
    return (
      <>
        <HelmetPart canonical={canonical} />
        {!this.state.showMap && (
          <SearchHotelsContainer
            hotels={this.props.filteredHotels}
            isLoaded={this.props.loadedHotels}
            handleClickFilter={this.handleClickFilter}
            handleClickMap={this.handleOpenMap}
          />
        )}
        {this.props.loadedHotels && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Filters
              handleClose={this.handleClickFilter}
              openDrawer={this.state.showFilter}
              stateShowMap={this.state.showMap}
            />
          </Grid>
        )}
        {this.props.loadedHotels && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SortingContainer stateShowMap={this.state.showMap} />
          </Grid>
        )}
        {this.state.showMap && (
          <MapBox
            hotels={this.props.filteredHotels}
            handleCloseMap={this.handleCloseMap}
            handleClickFilter={this.handleClickFilter}
          />
        )}
        <Grid item className={classes.mainPage}>
          {this.props.loadedHotels && !this.state.showMap && (
            <ListHotels
              strings={strings}
              hotels={this.props.filteredHotels}
              isLoaded={this.props.loadedHotels}
              handleChangeHotelsPerPage={this.handleChangeHotelsPerPage}
              hotelsPerPage={this.state.hotelsPerPage}
              handleResetAllFilters={this.handleResetAllFilters}
            />
          )}
          {!this.props.loadedHotels &&
            !this.state.showMap &&
            !this.state.showFilter && (
              <Grid container justify="center">
                <CircularProgress className={classes.progress} />
              </Grid>
            )}
        </Grid>

        {!this.state.showMap && <BottomPart />}
      </>
    );
  }
}

MainPage.propTypes = {
  classes: PropTypes.object.isRequired,
  loadedHotels: PropTypes.bool.isRequired,
  filteredHotels: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  canonical: PropTypes.string.isRequired,
  lang: PropTypes.string,
  getHotels: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  resetAllFilters: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  hotels: getAllHotels(state),
  loadingHotels: getHotelsLoading(state),
  loadedHotels: getHotelsLoaded(state),
  errorGetHotels: getHotelsError(state),
  filters: getAllFilters(state),
  filteredHotels: filteredAndSorteredHotel(state)
});

const mapDispatchToProps = {
  getHotels,
  changeLanguage,
  resetAllFilters
};

const MainPageCompose = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate("MainPage")
)(MainPage);
export { MainPageCompose as MainPage };
