import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
//import MenuIcon from '@material-ui/icons/Menu';

import { ChangeLanguages } from "./";

import img from "../logo.png";
import translate from "../HOC/translate";
import { history } from "../_helpers";
//import { ChangeLanguages } from './ChangeLanguages';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  link: {
    margin: theme.spacing(1),
    color: "white"
  },
  svgIcon: {
    width: 36,
    height: 36
  },
  logo: {
    width: 132,
    cursor: "pointer"
  }
});

class NavBar extends Component {


  render() {
    const { classes, strings } = this.props;
    return (
      <AppBar color="default" position="sticky">
        <Toolbar variant="dense">
          <div className={classes.grow}>
            <img
              className={classes.logo}
              alt="logo"
              src={img}
              onClick={() => history.push("/")}
            />
          </div>
          <ChangeLanguages strings={strings} />
        </Toolbar>
      </AppBar>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};


const NavbarWithCompose = compose(
  withRouter,
  translate("Navbar"),
  connect(null),
  withStyles(styles)
)(NavBar);
export { NavbarWithCompose as NavBar };
