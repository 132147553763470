const hotelPageStyle = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(2))]: {
      //maxWidth: 966,
      maxWidth: 1024,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  progress: {
    margin: theme.spacing(2)
  },
  textNewLine: {
    whiteSpace: "pre-wrap"
  },
  hotelTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
});

export default hotelPageStyle;
