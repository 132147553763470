import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import SearchHotels from "./SearchHotels";
import translate from "../../HOC/translate";
import { history } from "../../_helpers";
import { getLanguage } from "../../_reducers/selectors";

const multiLengText = currentLanguage => {
  switch (currentLanguage) {
    case "en":
      return 0;
    case "uk":
      return 1;
    case "ru":
      return 2;
    default:
      return 0;
  }
};

function makeAutoSuggestions(hotels, currentLanguage) {
  const id = multiLengText(currentLanguage);
  let hotelnames = [];
  hotels.forEach(hotel => {
    hotelnames.push({
      label: hotel.hotelname[currentLanguage],
      city: hotel.city[id].value,
      region: hotel.region[id].value,
      img: hotel.imgHotelPathArray[0],
      hotelname: hotel.hotelname.en
    });
    currentLanguage !== "en" &&
      hotelnames.push({
        label: hotel.hotelname.en,
        city: hotel.city[id].value,
        region: hotel.region[id].value,
        img: hotel.imgHotelPathArray[0],
        hotelname: hotel.hotelname.en
      });
  });
  return hotelnames;
}

const SearchHotelsContainer = props => {
  const [suggestion, setSuggestion] = React.useState(null);
  const {
    strings,
    currentLanguage,
    isLoaded,
    hotels,
    handleClickFilter,
    handleClickMap
  } = props;

  const handleClickSearch = () => {
    suggestion &&
      history.push({
        pathname:
          props.currentLanguage !== "uk"
            ? `${props.currentLanguage}/hotel/${suggestion.hotelname.replace(
                / /g,
                "-"
              )}`
            : `ua/hotel/${suggestion.hotelname.replace(/ /g, "-")}`
      });
  };

  const handleAutocompleteChange = suggestion => {
    setSuggestion(suggestion);
  };

  //prepare autosuggestions array of object -> hotelnames, and for currentLanguage
  let hotelnames = [];
  if (isLoaded) hotelnames = makeAutoSuggestions(hotels, currentLanguage);

  return (
    <SearchHotels
      handleClickFilter={handleClickFilter}
      handleClickMap={handleClickMap}
      handleClickSearch={handleClickSearch}
      hotelnames={hotelnames}
      strings={strings}
      handleAutocompleteChange={handleAutocompleteChange}
    />
  );
};

SearchHotelsContainer.propTypes = {
  strings: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  hotels: PropTypes.array.isRequired,
  handleClickFilter: PropTypes.func.isRequired,
  handleClickMap: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  currentLanguage: getLanguage(state)
});

const SearchHotelsContainerCompose = compose(
  connect(mapStateToProps),
  translate("Search")
)(SearchHotelsContainer);
export { SearchHotelsContainerCompose as SearchHotelsContainer };
