import React from "react";
import PropTypes from "prop-types";
import Rating from "material-ui-rating";

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Drawer,
  Tab,
  IconButton
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../_jss/HotelPage/reviewsDrawerStyled";

const useStyles = makeStyles(styles);

export const ReviewsDrawer = props => {
  const classes = useStyles();
  const {
    reviews,
    clickTabOpenReviewsDrawer,
    openReviewsDrawer,
    reviewsName
  } = props;
  return (
    <div>
      <Tab onClick={clickTabOpenReviewsDrawer(true)} label={reviewsName} />
      <Drawer
        anchor="right"
        open={openReviewsDrawer}
        onClose={clickTabOpenReviewsDrawer(false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={clickTabOpenReviewsDrawer(false)}
          onKeyDown={clickTabOpenReviewsDrawer(false)}
        >
          <div className={classes.list}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                className={classes.button}
                color="primary"
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            {reviews.map((review, indexReview) => (
              <Card key={indexReview} classes={{ root: classes.root }}>
                <CardHeader
                  classes={{ root: classes.cardHeaderRoot }}
                  avatar={
                    <Avatar
                      src={review.profile_photo_url}
                      className={classes.avatar}
                    />
                  }
                  title={review.author_name}
                  subheader={review.relative_time_description}
                />
                <CardContent classes={{ root: classes.cardContentRoot }}>
                  <Rating readOnly value={review.rating} max={5} />
                  {review.text}
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

ReviewsDrawer.propTypes = {
  reviews: PropTypes.array.isRequired,
  clickTabOpenReviewsDrawer: PropTypes.func.isRequired,
  openReviewsDrawer: PropTypes.bool.isRequired,
  reviewsName: PropTypes.string.isRequired
};
