import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import styles from "../../../_jss/Filters/filtersStyle";

const useStyles = makeStyles(styles);

function checkIfServiceItemsExist(service) {
  return (
    service.items.filter(item => {
      return item.isFilter === true;
    }).length > 0
  );
}

export const FilterServiceMobile = props => {
  const { strings, handleChange, filters } = props;
  const classes = useStyles();
  return (
    <>
      {filters.map(
        (service, indexService) =>
          checkIfServiceItemsExist(service) && (
            <React.Fragment key={indexService}>
              <FormLabel component="legend" className={classes.forLegend}>
                {strings.servicesName[indexService]}
              </FormLabel>
              <FormGroup key={indexService} row>
                {service.items.map(
                  (item, indexItem) =>
                    item.isFilter && (
                      <FormControlLabel
                        key={item.name}
                        control={
                          <Switch
                            checked={item.selected}
                            onChange={handleChange(indexService, indexItem)}
                            value={item.name}
                            color="primary"
                          />
                        }
                        label={strings.services[indexService][indexItem]}
                      />
                    )
                )}
              </FormGroup>
            </React.Fragment>
          )
      )}
    </>
  );
};

FilterServiceMobile.propTypes = {
  strings: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired
};
