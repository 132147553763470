import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

export const MetaHeadHelmet = props => {
  const { hotelname, description, strings, hotelnameUrl, langUrl } = props;
  const localesAlternative = ["ua", "en", "ru"];
  return (
    <Helmet>
      <link
        rel="canonical"
        href={`https://zamistom.kiev.ua/${langUrl}/hotel/${hotelnameUrl}`}
      ></link>
      {localesAlternative.map((locale, id) => (
        <link
          key={id}
          rel="alternate"
          hrefLang={locale === "ua" ? "uk" : locale}
          href={`https://zamistom.kiev.ua/${locale}/hotel/${hotelnameUrl}`}
        ></link>
      ))}
      <title>
        {strings.title1}
        {hotelname}
        {strings.title2}
      </title>
      <meta name="description" content={description} />
      <meta name="keywords" content={strings.title1 + hotelname} />
    </Helmet>
  );
};

MetaHeadHelmet.propTypes = {
  hotelname: PropTypes.string.isRequired,
  hotelnameUrl: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  langUrl: PropTypes.string.isRequired
};
