import {
  listItemIconRoot,
  listItem,
  listItemTextServiceName,
  listItemText,
  listItemIconRoomInfo
} from "../General/listStyle";

const servicesStyle = {
  divCount: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-between",
    whiteSpace: "pre-wrap",
    // columnCount : 1,
    // display: 'inline-block',
    backgroundColor: "white"
    // [theme.breakpoints.up('md')]: {
    //   columnCount : 3,
    //   clear: 'both',
    //   display: 'inline-block',
    // },
  },
  listItemTextPrimary: {
    fontSize: "0.8rem",
    lineHeight: 1
  },
  listItemIconRoot,
  listItem,
  listItemTextServiceName,
  listItemText,
  listItemIconRoomInfo
};

export default servicesStyle;
