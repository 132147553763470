import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu, MenuItem, IconButton } from "@material-ui/core";

import { changeLanguage } from "../_reducers/ducks-language";
import { getLanguage } from "../_reducers/selectors";

const styles = theme => ({
  labellIconButton: {
    fontSize: "1rem"
  },
  imgFlag: {
    paddingLeft: theme.spacing(0.5),
    width: 32,
    height: 16
  }
});

class MainMenu extends Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLangChoose = event => {
    const { lang } = event.target;
    this.props.changeLanguage(lang);
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    return (
      <React.Fragment>
        {/* <IconButton className={classes.menuButton} color="default" aria-label="Menu">
                <MenuIcon />
              </IconButton> */}
        <IconButton
          onClick={this.handleMenuOpen}
          classes={{ label: classes.labellIconButton }}
          color="primary"
          aria-label="MainMenu"
          aria-owns={anchorEl ? "main-menu" : undefined}
        >
          <MenuIcon />
        </IconButton>

        <Menu
          id="main-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem>Language</MenuItem>
          <MenuItem>Filters</MenuItem>
          <MenuItem>Map</MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentLanguage: getLanguage(state)
});

const mapDispatchToProps = {
  changeLanguage
};


const MainMenuCompose = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MainMenu);
export { MainMenuCompose as MainMenu };
