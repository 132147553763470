import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Link, Grid, Paper, Divider } from "@material-ui/core";
import ImgSert from "./img-sert";
import SocialIcon from "./social-icon";
import translate from "../HOC/translate";

const styles = theme => ({
  mainPage: {
    paddingTop: "16px",
    paddingBottom: "16px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(6))]: {
      marginTop: theme.spacing(3),
      maxWidth: 966,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "42px 0px"
    }
  },
  iconAlign: {
    textAlign: "center"
  }
});

class BottomPart extends Component {
  state = {};

  render() {
    const { classes, strings } = this.props;
    return (
      <Paper elevation={0}>
        <Grid container className={classes.mainPage}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center", paddingTop: 16 }}>
              <SocialIcon />
              <ImgSert classes={classes} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center", paddingTop: 16 }}>
              © 2012–2019,{" "}
              <Link href="https://zamistom.kiev.ua" color="primary">
                ZAMISTOM.KIEV.UA
              </Link>
              {strings.textDesc}
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

BottomPart.propTypes = {
  classes: PropTypes.object.isRequired
};

const BottomPartCompose = compose(
  withStyles(styles),
  translate("BottomPart")
)(BottomPart);

export { BottomPartCompose as BottomPart };
