export default {
  MetaHead: {
    title: "Знайти готелі, котеджи для відпочинку під Києвом на ",
    metaDesc1: "Знайти дешевий відпочинок онлайн під Києвом на ",
    metaDesc2:
      "Бази відпочинку, готелі, котеджі і вілли за низькими цінами під Києвом!",
    metaPropOgTitle: "Шукаєш відпочинок за містом? Перевір на ",
    metaPropOgDesc:
      "Пошук готелів, баз відпочинку, котеджів і вілл під Києвом. Відгуки, рейтинги, ціни. Знайди відпочинок прямо зараз!",
    metaPropOgLocale: "uk_UA",
    metaTwitterTitle: "Шукаєш відпочинок за містом? Перевір на ",
    metaTwitterDesc:
      "Пошук готелів, баз відпочинку, котеджів і вілл під Києвом. Відгуки, рейтинги, ціни. Знайди відпочинок прямо зараз!"
  },
  BottomPart: {
    textDesc: " - готелі, котеджи для відпочинку під Києвом."
  },
  MapBox: {
    buttonLabelFilter: "Фільтри",
    buttonLabelClose: "Закрити карту",
    countOfGalleryImg: " з ",
    buttonDetailsText: "Детальніше",
    buttonCloseCard: "Закрити"
  },
  Navbar: {
    tooltipChangeLang: "Змінити мову",
    tooltipEnerOnSite: "Вхід і реєстрація"
  },
  RoomsType: {
    descriptionHeader: "Про номер",
    roomsTypeHeader: "Доступні номери",
    name: ["Ванна кімната", "Зручності у номері"],
    services: [
      [
        "Душ",
        "Ванна",
        "Біде",
        "Гігієнічний душ",
        "Туалет",
        "Туалетні приналежності",
        "Рушники",
        "Халат",
        "Фен",
        "Одноразові щітки",
        "Джакузі",
        "Пральна машина"
      ],
      [
        "Міні-бар",
        "DVD-плеєр",
        "Радіатори опалення",
        "Капці",
        "Супутникові канали",
        "Інтернет",
        "Телевізор з пласким екраном",
        "Паркетна підлога",
        "Постільна білизна",
        "Москітна сітка",
        "Шафа/гардероб",
        "Письмовий/журнальний столик",
        "Кондиціонер",
        "Тераса",
        "Балкон",
        "Килимове покриття",
        "Вікна в підлогу",
        "Нерозкладний диван",
        "Крісла",
        "Електричний чайник",
        "Мікрохвильова піч",
        "Холодильник",
        "Плита",
        "Столові прилади"
      ]
    ],
    roomPriceText1: "Ціна за ніч від: ",
    roomPriceText2: " Грн.",
    countOfGalleryImg: " з "
  },
  Services: {
    name: [
      "Спорт і відпочинок",
      "Басейн & SPA",
      "Територія",
      "Їжа і напої",
      "Загальні",
      "Мова спілкування",
      "WiFi & Інтернет"
    ],
    services: [
      //["Котедж", "Бунгало", "Головна будівля", "Вілла", "Намет"],
      [
        "Пляж",
        "Прокат човнів/катамаранів",
        "Прокат гіроскутеров",
        "Тенісний корт",
        "Дитячий клуб",
        "Дитячий майданчик",
        "Верхова їзда",
        "Велосипедні маршрути",
        "Прокат велосипедів",
        "Дартс",
        "Настільний теніс",
        "Більярд",
        "Риболовля",
        "Зоопарк",
        "Пейнтбол",
        "Мотузковий парк",
        "Батут"
      ],
      [
        "Солярій",
        "Масаж",
        "СПА зона",
        "Косметичний кабінет",
        "Лазня: російська/фінська",
        "Сауна",
        "Басейн",
        "Критий басейн",
        "Дитячий басейн",
        "Басейн з підігрівом"
      ],
      [
        "Ліс",
        "Сад",
        "Тераса",
        "Барбекю",
        "Оренда шампурів/мангала",
        "Альтанки платно",
        "Альтанки безкоштовно",
        "Лавочки",
        "Газон",
        "Пасіка",
        "Магазин продуктовий",
        "Магазин сувенірний",
        "Ставок",
        "Озеро",
        "Річка"
      ],
      [
        "Ресторан",
        "Кафе",
        "Бар",
        "Кальян",
        "Дієтичне харчування",
        "Алкогольні напої",
        "Приготування на мангалі",
        "Їжа з печі",
        "Дитяче меню",
        "Самостійне приготування",
        "Доставка їжі в номер"
      ],
      [
        "Медичний персонал",
        "Місця для куріння",
        "Екскурсійне бюро",
        "Експрес реєстрація заїзду/виїзду",
        "Індівідуальна реєстрація",
        "Термінал",
        "Оплата банківською картою",
        "Цілодобова стійка реєстрації",
        "Охорона території",
        "Конференц зал",
        "Організація урочистостей",
        "Парковка платна",
        "Парковка безкоштовна",
        "Розміщення з тваринами",
        "Дитячі ліжка в номер",
        "Прибирання номеру",
        "Няня"
      ],
      ["Англійська", "Російська", "Українська"],
      ["На території", "У номерах", "В ресторані", "В головній будівлі"]
    ]
  },
  ListHotels: {
    title:
      "Не тільки готелі ... Відкрийте для себе ідеальний комфорт будинків і апартаментів",
    distanceFromKiev: "Відстань від Києва : ",
    typeOfAccommodationText: "Тип проживання : ",
    typeOfAccommodation: [
      "Котедж",
      "Бунгало",
      "Головна будівля",
      "Вілла",
      "Намет"
    ],
    foodAndDrinkText: "Їжа та напої : ",
    foodAndDrink: [
      "Ресторан",
      "Кафе",
      "Бар",
      "Кальян",
      "Дієтичне харчування",
      "Алкогольні напої",
      "Приготування на мангалі",
      "Їжа з печі",
      "Дитяче меню",
      "Самостійне приготування",
      "Доставка їжі в номер"
    ],
    activitiesText: "Розваги: ",
    activities: [
      "Пляж",
      "Прокат човнів/катамаранів",
      "Прокат гіроскутеров",
      "Тенісний корт",
      "Дитячий клуб",
      "Дитячий майданчик",
      "Верхова їзда",
      "Велосипедні маршрути",
      "Прокат велосипедів",
      "Дартс",
      "Настільний теніс",
      "Більярд",
      "Риболовля",
      "Зоопарк",
      "Пейнтбол",
      "Мотузковий парк",
      "Батут"
    ],
    descriptionTitle: "Про комплекс",
    buttonDetailsText: "Детальніше",
    buttonReviewText: "Відгуки",
    buttonShowMoreHotels: "ПОКАЗАТИ БІЛЬШЕ ГОТЕЛІВ",
    countOfGalleryImg: " з ",
    noResult: "Результатів не знайдено!",
    buttonResetAllFitlersText: "ОЧИСТИТИ ВСЕ ФІЛЬТРИ",
    priceFrom: "від",
    priceNight: "доба"
  },
  HotelPage: {
    title1: "Комплекс відпочинку ",
    title2: ". Відпочинок під Києвом, заміський відпочинок",
    descriptionTitle: "Про комплекс",
    servicesTitle: "Послуги - ",
    roomsTypeTitle: "Доступні номери",
    reviewsTitle: "Відгуки гостей",
    tabs: {
      description: "Опис",
      services: "Послуги",
      placement: "Номери",
      reviews: "Відгуки"
    },
    // tabs : {description : 'Про комплекс', services: 'Зручності та послуги', placement: 'Умови розміщення',
    // reviews: 'Відгуки гостей',},
    countOfGalleryImg: " з ",
    breadCrumbsMain: "Головна"
  },
  MainPage: {
    popDest:
      "Який варіант проживання б ви не шукали, ви знайдете його у нас ..."
  },
  UserMenu: {
    personalAccount: "Особистий кабінет",
    orders: "Замовлення",
    discount: "Знижки та подарунки",
    leave: "Вийти"
  },
  Search: {
    buttonLabelFilter: "Фільтри",
    buttonLabelSearch: "Знайти",
    buttonLabelOnMap: "Показати карту",
    buttonLabelOnMapMobile: "Карта",
    textFieldLabel: "Пошук",
    //textFieldPlaceholder : 'Район області, місто',
    textFieldPlaceholder: "Готель або місто",
    filter: {
      buttonLabelClose: "Закрити"
    },
    servicesName: [
      "Тип проживання",
      "Спорт і відпочинок",
      "Басейн & SPA",
      "Територія",
      "Їжа і напої",
      "Загальні",
      "Мова спілкування",
      "Інтернет"
    ],
    services: [
      ["Котедж", "Бунгало", "Головна будівля", "Вілла", "Намет"],
      [
        "Пляж",
        "Прокат човнів/катамаранів",
        "Прокат гіроскутеров",
        "Тенісний корт",
        "Дитячий клуб",
        "Дитячий майданчик",
        "Верхова їзда",
        "Велосипедні маршрути",
        "Прокат велосипедів",
        "Дартс",
        "Настільний теніс",
        "Більярд",
        "Риболовля",
        "Зоопарк",
        "Пейнтбол",
        "Мотузковий парк",
        "Батут"
      ],
      [
        "Солярій",
        "Масаж",
        "СПА зона",
        "Косметичний кабінет",
        "Лазня: російська/фінська",
        "Сауна",
        "Басейн",
        "Критий басейн",
        "Дитячий басейн",
        "Басейн з підігрівом"
      ],
      [
        "Ліс",
        "Сад",
        "Тераса",
        "Барбекю",
        "Оренда шампурів/мангала",
        "Альтанки платно",
        "Альтанки безкоштовно",
        "Лавочки",
        "Газон",
        "Пасіка",
        "Магазин продуктовий",
        "Магазин сувенірний",
        "Ставок",
        "Озеро",
        "Річка"
      ],
      [
        "Ресторан",
        "Кафе",
        "Бар",
        "Кальян",
        "Дієтичне харчування",
        "Алкогольні напої",
        "Приготування на мангалі",
        "Їжа з печі",
        "Дитяче меню",
        "Самостійне приготування",
        "Доставка їжі в номер"
      ],
      [
        "Медичний персонал",
        "Місця для куріння",
        "Екскурсійне бюро",
        "Експрес реєстрація заїзду/виїзду",
        "Індівідуальна реєстрація",
        "Термінал",
        "Оплата банківською картою",
        "Цілодобова стійка реєстрації",
        "Охорона території",
        "Конференц зал",
        "Організація урочистостей",
        "Парковка платна",
        "Парковка безкоштовна",
        "Розміщення з тваринами",
        "Дитячі ліжка в номер",
        "Прибирання номеру",
        "Няня"
      ],
      ["Англійська", "Російська", "Українська"],
      [
        "Wi-Fi на території",
        "Wi-Fi у номерах",
        "Wi-Fi в ресторані",
        "Wi-Fi в головній будівлі"
      ]
    ],
    filterOfServices: {
      accommodationFilterChecked: [
        "Котедж",
        "Бунгало",
        "Головна будівля",
        "Вілла",
        "Намет"
      ],
      buttonLabelClose: "Закрити",
      distanceFromKiev: {
        listItemText: "ВІДСТАНЬ ВІД КИЄВА",
        distances: [
          {
            href: "#simple-list",
            value: "до 5 км"
          },
          {
            href: "#simple-list",
            value: "від 5 до 30 км"
          },
          {
            href: "#simple-list",
            value: "від 30 до 60 км"
          },
          {
            href: "#simple-list",
            value: "до 100 км"
          },
          {
            href: "#simple-list",
            value: "більше 100 км"
          }
        ]
      },
      regionalAreas: {
        listItemText: "РАЙОНИ ОБЛАСТІ",
        regions: [
          {
            href: "#simple-list",
            name: "Бориспільський"
          },
          {
            href: "#simple-list",
            name: "Бородянський"
          },
          {
            href: "#simple-list",
            name: "Броварський"
          },
          {
            href: "#simple-list",
            name: "Васильківський"
          },
          {
            href: "#simple-list",
            name: "Вишгородський"
          },
          {
            href: "#simple-list",
            name: "Києво-Святошинський"
          },
          {
            href: "#simple-list",
            name: "Макарівський"
          }
        ]
      },
      towns: {
        listItemText: "КИЇВСЬКА ОБЛАСТЬ",
        cities: [
          {
            href: "/бази-відпочинку-білої-церкви",
            name: "Біла Церква"
          },
          {
            href: "/бази-відпочинку-білогородки",
            name: "Білогородка"
          },
          {
            href: "#simple-list",
            name: "Бориспіль +10 км"
          },
          {
            href: "#simple-list",
            name: "Боярка"
          },
          {
            href: "#simple-list",
            name: "Бровари"
          },
          {
            href: "#simple-list",
            name: "Буча"
          },
          {
            href: "#simple-list",
            name: "Васильків"
          },
          {
            href: "#simple-list",
            name: "Вишневе +10 км"
          },
          {
            href: "#simple-list",
            name: "Ворзель"
          },
          {
            href: "#simple-list",
            name: "Вишгород +10 км"
          },
          {
            href: "#simple-list",
            name: "Гатне"
          },
          {
            href: "#simple-list",
            name: "Глеваха"
          },
          {
            href: "#simple-list",
            name: "Гнідин"
          },
          {
            href: "#simple-list",
            name: "Гора"
          },
          {
            href: "#simple-list",
            name: "Гостомель"
          },
          {
            href: "#simple-list",
            name: "Зазим'я"
          },
          {
            href: "#simple-list",
            name: "Ірпінь +10 км"
          },
          {
            href: "#simple-list",
            name: "Київ"
          },
          {
            href: "#simple-list",
            name: "Коцюбинське"
          },
          {
            href: "#simple-list",
            name: "Крюківщина"
          },
          {
            href: "#simple-list",
            name: "Макарів"
          },
          {
            href: "#simple-list",
            name: "Мила"
          },
          {
            href: "#simple-list",
            name: "Немішаєве"
          },
          {
            href: "#simple-list",
            name: "Нові Петрівці"
          },
          {
            href: "#simple-list",
            name: "Обухів"
          },
          {
            href: "#simple-list",
            name: "Петропавлівська Борщагівка"
          },
          {
            href: "#simple-list",
            name: "Підгірці"
          },
          {
            href: "#simple-list",
            name: "Святопетрівське"
          },
          {
            href: "#simple-list",
            name: "Софіївська Борщагівка"
          },
          {
            href: "#simple-list",
            name: "Щасливе"
          },
          {
            href: "#simple-list",
            name: "Тарасівка"
          },
          {
            href: "#simple-list",
            name: "Требухів"
          },
          {
            href: "#simple-list",
            name: "Українка"
          },
          {
            href: "#simple-list",
            name: "Ходосівка"
          },
          {
            href: "#simple-list",
            name: "Хотів"
          },
          {
            href: "#simple-list",
            name: "Чабани"
          },
          {
            href: "#simple-list",
            name: "Чайки"
          },
          {
            href: "#simple-list",
            name: "Ясногородка"
          }
        ]
      },
      typeOfAccommodation: {
        formLabel: "Тип проживання",
        formControlLabelCottage: "Котеджі",
        formControlLabelBungalow: "Бунгало",
        formControlLabelMainBuilding: "Головна будівля",
        formControlLabelVilla: "Вілла",
        formControlLabelTent: "Шатер"
      },
      destinationSlidder: {
        formLabel: "Відстань від міста",
        formLabelKm: "км"
      },
      facilitiesHotels: {
        formLabel: "Послуги готелю",
        formControlLabelWiFi: "Wi-Fi на території",
        formControlLabelPool: "Басейн",
        formControlLabelGym: "Спортзал",
        formControlLabelFish: "Риболовля",
        formControlLabelHorse: "Прогулянки на конях",
        formControlLabelSpa: "SPA зона"
      },
      facilitiesRoom: {
        formLabel: "Зручності в номері",
        formControlLabelWiFi: "Wi-Fi в номері",
        formControlLabelAir: "Кондиціонер",
        formControlLabelFridge: "Холодильник",
        formControlLabelTv: "Телевізор",
        formControlLabelSafe: "Сейф"
      }
    },
    priceFilter: {
      name: "Ціна",
      anyPrice: "Будь-яка ціна",
      underPrice: "Менше",
      currency: "UAH"
    }
  },
  Sorting: {
    byPopularity: "За популярністю",
    cheapFirst: "Спочатку дешеві",
    expensiveFirst: "Спочатку дорогі",
    byReviewRank: "За оцінкою мешканців",
    discountFirst: "Спочатку зі знижками"
  }
};
