export default {
  MetaHead: {
    title: "Найти отели, коттеджи для отдыха под Киевом на ",
    metaDesc1: "Найти дешевый отдых онлайн под Киевом на ",
    metaDesc2:
      "Базы отдыха, отели, коттеджи и виллы под Киевом по низким ценам!",
    metaPropOgTitle: "Ищешь отдых за городом? Проверь на ",
    metaPropOgDesc:
      "Поиск отелей, баз отдыха, коттеджей и вилл под Киевом. Отзывы, рейтинги, цены. Найди отдых прямо сейчас!",
    metaPropOgLocale: "ru_RU",
    metaTwitterTitle: "Ищешь отдых за городом? Проверь на ",
    metaTwitterDesc:
      "Поиск отелей, баз отдыха, коттеджей и вилл под Киевом. Отзывы, рейтинги, цены. Найди отдых прямо сейчас!"
  },
  BottomPart: {
    textDesc: " - гостиницы, коттеджи для отдыха под Киевом."
  },
  MapBox: {
    buttonLabelFilter: "Фильтры",
    buttonLabelClose: "Закрыть Карту",
    countOfGalleryImg: " из ",
    buttonDetailsText: "Подробнее",
    buttonCloseCard: "Закрыть"
  },
  Navbar: {
    tooltipChangeLang: "Изменить язык",
    tooltipEnerOnSite: "Вход и регистрация"
  },
  RoomsType: {
    descriptionHeader: "О номере",
    roomsTypeHeader: "Доступные номера",
    name: ["Ванная комната", "Удобства в номере"],
    services: [
      [
        "Душ",
        "Ванная",
        "Биде",
        "Гигиенический душ",
        "Туалет",
        "Туалетные принадлежности",
        "Полотенца",
        "Халат",
        "Фен",
        "Одноразовые щетки",
        "Джакузи",
        "Стиральная машина"
      ],
      [
        "Мини-бар",
        "DVD-плеер",
        "Радиаторы отопления",
        "Тапочки",
        "Спутниковые каналы",
        "Интернет",
        "Телевизор с плоским экраном",
        "Паркетные полы",
        "Постельное белье",
        "Москитная сетка",
        "Шкаф/гардероб",
        "Письменный/журнальный столик",
        "Кондиционер",
        "Терасса",
        "Балкон",
        "Ковровое покрытие",
        "Окна в пол",
        "Нераскладной диван",
        "Кресла",
        "Электрический чайник",
        "Микроволновка",
        "Холодильник",
        "Плита",
        "Столовые приборы"
      ]
    ],
    roomPriceText1: "Цена за ночь от: ",
    roomPriceText2: " Грн.",
    countOfGalleryImg: " из "
  },
  Services: {
    name: [
      "Спорт и отдых",
      "Бассейн & SPA",
      "Территория",
      "Питание и напитки",
      "Общие",
      "Персонал говорит",
      "WiFi & Интернет"
    ],
    services: [
      [
        "Пляж",
        "Прокат лодок/катамаранов",
        "Прокат гироскутеров",
        "Теннисный корт",
        "Детский клуб",
        "Детская площадка",
        "Верховая езда",
        "Велосипедные маршруты",
        "Прокат велосипедов",
        "Дартс",
        "Настольный теннис",
        "Бильярд",
        "Рыбалка",
        "Зоопарк",
        "Пейнтбол",
        "Веревочный парк",
        "Батут"
      ],
      [
        "Солярий",
        "Массаж",
        "СПА зона",
        "Косметический кабинет",
        "Баня: русская/финская",
        "Сауна",
        "Бассейн",
        "Крытый бассейн",
        "Детский бассейн",
        "Бассейн с подогревом"
      ],
      [
        "Лес",
        "Сад",
        "Терраса",
        "Барбекю",
        "Аренда шампуров/мангалов",
        "Беседки платно",
        "Беседки бесплатно",
        "Скамейки",
        "Газон",
        "Пасека",
        "Магазин продуктовый",
        "Магазин сувенирный",
        "Пруд",
        "Озеро",
        "Речка"
      ],
      [
        "Ресторан",
        "Кафе",
        "Бар",
        "Кальян",
        "Диетическое питание",
        "Алкогольные напитки",
        "Приготовление на мангале",
        "Еда из печи",
        "Детское меню",
        "Самостоятельное приготовление",
        "Доставка еды в номер"
      ],
      [
        "Медицинский персонал",
        "Места для курения",
        "Экскурсионное бюро",
        "Экспресс регистрация заезда/выезда",
        "Индивидуальная регистрация",
        "Терминал",
        "Оплата банковской картой",
        "Круглосуточная стойка регистратуры",
        "Охрана территории",
        "Конференц зал",
        "Организация мероприятий",
        "Парковка платная",
        "Парковка бесплатная",
        "Размещение с животными",
        "Детские кровати в номер",
        "Уборка номера",
        "Няня"
      ],
      ["Английский", "Русский", "Украинский"],
      ["На территории", "В номерах", "В ресторане", "В главном здании"]
    ]
  },
  ListHotels: {
    title:
      "Не только отели... Откройте для себя идеальный комфорт домов и апартаментов",
    distanceFromKiev: "Расстояние от Киева : ",
    typeOfAccommodationText: "Тип проживания : ",
    typeOfAccommodation: [
      "Коттедж",
      "Бунгало",
      "Главный корпус",
      "Вилла",
      "Палатка"
    ],
    foodAndDrinkText: "Еда и напитки : ",
    foodAndDrink: [
      "Ресторан",
      "Кафе",
      "Бар",
      "Кальян",
      "Диетическое питание",
      "Алкогольные напитки",
      "Приготовление на мангале",
      "Еда из печи",
      "Детское меню",
      "Самостоятельное приготовление",
      "Доставка еды в номер"
    ],
    activitiesText: "Развлечения: ",
    activities: [
      "Пляж",
      "Прокат лодок/катамаранов",
      "Прокат гироскутеров",
      "Теннисный корт",
      "Детский клуб",
      "Детская площадка",
      "Верховая езда",
      "Велосипедные маршруты",
      "Прокат велосипедов",
      "Дартс",
      "Настольный теннис",
      "Бильярд",
      "Рыбалка",
      "Зоопарк",
      "Пейнтбол",
      "Веревочный парк",
      "Батут"
    ],
    descriptionTitle: "Про комплекс",
    buttonDetailsText: "Подробнее",
    buttonReviewText: "Отзывы",
    buttonShowMoreHotels: "ПОКАЗАТЬ БОЛЬШЕ ОТЕЛЕЙ",
    countOfGalleryImg: " из ",
    noResult: "Результатов не найдено!",
    buttonResetAllFitlersText: "ОЧИСТИТЬ ВСЕ ФИЛЬТРЫ",
    priceFrom: "от",
    priceNight: "сутки"
  },
  HotelPage: {
    title1: "Комплекс отдыха ",
    title2: ". Отдых под Киевом, загородный отдых",
    descriptionTitle: "О комплексе",
    servicesTitle: "Услуги - ",
    roomsTypeTitle: "Доступные номера",
    reviewsTitle: "Отзывы гостей",
    tabs: {
      description: "Описание",
      services: "Услуги",
      placement: "Номера",
      reviews: "Отзывы"
    },
    // tabs : {description : 'Описание', services: 'Удобства и услуги', placement: 'Условия размещения',
    // reviews: 'Отзывы гостей',},
    countOfGalleryImg: " из ",
    breadCrumbsMain: "Главная"
  },
  MainPage: {
    popDest: "Какой вариант проживания бы вы ни искали, вы найдете его у нас..."
  },
  UserMenu: {
    personalAccount: "Личный кабинет",
    orders: "Заказы",
    discount: "Скидки и подарки",
    leave: "Выйти"
  },
  Search: {
    buttonLabelFilter: "Фильтры",
    buttonLabelSearch: "Найти",
    buttonLabelOnMap: "Показать карту",
    buttonLabelOnMapMobile: "Карта",
    textFieldLabel: "Поиск",
    //textFieldPlaceholder : 'Район области, город',
    textFieldPlaceholder: "Отель или город",
    filter: {
      buttonLabelClose: "Закрыть"
    },
    servicesName: [
      "Тип проживания",
      "Спорт и отдых",
      "Бассейн & SPA",
      "Территория",
      "Питание и напитки",
      "Общие",
      "Персонал говорит",
      "Интернет"
    ],
    services: [
      ["Коттедж", "Бунгало", "Главный корпус", "Вилла", "Палатка"],
      [
        "Пляж",
        "Прокат лодок/катамаранов",
        "Прокат гироскутеров",
        "Теннисный корт",
        "Детский клуб",
        "Детская площадка",
        "Верховая езда",
        "Велосипедные маршруты",
        "Прокат велосипедов",
        "Дартс",
        "Настольный теннис",
        "Бильярд",
        "Рыбалка",
        "Зоопарк",
        "Пейнтбол",
        "Веревочный парк",
        "Батут"
      ],
      [
        "Солярий",
        "Массаж",
        "СПА зона",
        "Косметический кабинет",
        "Баня: русская/финская",
        "Сауна",
        "Бассейн",
        "Крытый бассейн",
        "Детский бассейн",
        "Бассейн с подогревом"
      ],
      [
        "Лес",
        "Сад",
        "Терраса",
        "Барбекю",
        "Аренда шампуров/мангалов",
        "Беседки платно",
        "Беседки бесплатно",
        "Скамейки",
        "Газон",
        "Пасека",
        "Магазин продуктовый",
        "Магазин сувенирный",
        "Пруд",
        "Озеро",
        "Речка"
      ],
      [
        "Ресторан",
        "Кафе",
        "Бар",
        "Кальян",
        "Диетическое питание",
        "Алкогольные напитки",
        "Приготовление на мангале",
        "Еда из печи",
        "Детское меню",
        "Самостоятельное приготовление",
        "Доставка еды в номер"
      ],
      [
        "Медицинский персонал",
        "Места для курения",
        "Экскурсионное бюро",
        "Экспресс регистрация заезда/выезда",
        "Индивидуальная регистрация",
        "Терминал",
        "Оплата банковской картой",
        "Круглосуточная стойка регистратуры",
        "Охрана территории",
        "Конференц зал",
        "Организация мероприятий",
        "Парковка платная",
        "Парковка бесплатная",
        "Размещение с животными",
        "Детские кровати в номер",
        "Уборка номера",
        "Няня"
      ],
      ["Английский", "Русский", "Украинский"],
      [
        "Wi-Fi на территории",
        "Wi-Fi в номерах",
        "Wi-Fi в ресторане",
        "Wi-Fi в главном здании"
      ]
    ],
    filterOfServices: {
      accommodationFilterChecked: [
        "Коттедж",
        "Бунгало",
        "Главный корпус",
        "Вилла",
        "Палатка"
      ],
      buttonLabelClose: "Закрыть",
      distanceFromKiev: {
        listItemText: "РАССТОЯНИЕ ОТ КИЕВА",
        distances: [
          {
            href: "#simple-list",
            value: "до 5 км"
          },
          {
            href: "#simple-list",
            value: "от 5 до 30 км"
          },
          {
            href: "#simple-list",
            value: "от 30 до 60 км"
          },
          {
            href: "#simple-list",
            value: "до 100 км"
          },
          {
            href: "#simple-list",
            value: "больше 100 км"
          }
        ]
      },
      regionalAreas: {
        listItemText: "РАЙОНЫ ОБЛАСТИ",
        regions: [
          {
            href: "#simple-list",
            name: "Бориспольский"
          },
          {
            href: "#simple-list",
            name: "Бородянский"
          },
          {
            href: "#simple-list",
            name: "Броварской"
          },
          {
            href: "#simple-list",
            name: "Васильковский"
          },
          {
            href: "#simple-list",
            name: "Вышгородский"
          },
          {
            href: "#simple-list",
            name: "Киево-Святошинский"
          },
          {
            href: "#simple-list",
            name: "Обуховский"
          }
        ]
      },
      towns: {
        listItemText: "КИЕВСКАЯ ОБЛАСТЬ",
        cities: [
          {
            href: "/базы-отдыха-белой-церкви",
            name: "Белая Церковь"
          },
          {
            href: "#simple-list",
            name: "Белогородка"
          },
          {
            href: "#simple-list",
            name: "Борисполь +10 км"
          },
          {
            href: "#simple-list",
            name: "Боярка"
          },
          {
            href: "#simple-list",
            name: "Бровары"
          },
          {
            href: "#simple-list",
            name: "Буча"
          },
          {
            href: "#simple-list",
            name: "Васильков"
          },
          {
            href: "#simple-list",
            name: "Вишневое +10 км"
          },
          {
            href: "#simple-list",
            name: "Ворзель"
          },
          {
            href: "#simple-list",
            name: 'Вышгород +10 км"'
          },
          {
            href: "#simple-list",
            name: "Гатное"
          },
          {
            href: "#simple-list",
            name: "Глеваха"
          },
          {
            href: "#simple-list",
            name: "Гнедин"
          },
          {
            href: "#simple-list",
            name: "Гора"
          },
          {
            href: "#simple-list",
            name: "Гостомель"
          },
          {
            href: "#simple-list",
            name: "Зазимье"
          },
          {
            href: "#simple-list",
            name: "Ирпень +10 км"
          },
          {
            href: "#simple-list",
            name: "Киев"
          },
          {
            href: "#simple-list",
            name: "Коцюбинское"
          },
          {
            href: "#simple-list",
            name: "Крюковщина"
          },
          {
            href: "#simple-list",
            name: "Макаров"
          },
          {
            href: "#simple-list",
            name: "Мила"
          },
          {
            href: "#simple-list",
            name: "Немешаево"
          },
          {
            href: "#simple-list",
            name: "Новые Петровцы"
          },
          {
            href: "#simple-list",
            name: "Обухов"
          },
          {
            href: "#simple-list",
            name: "Петропавловская Борщаговка"
          },
          {
            href: "#simple-list",
            name: "Подгорцы"
          },
          {
            href: "#simple-list",
            name: "Святопетровское"
          },
          {
            href: "#simple-list",
            name: "Софиевская Борщаговка"
          },
          {
            href: "#simple-list",
            name: "Счастливое"
          },
          {
            href: "#simple-list",
            name: "Тарасовка"
          },
          {
            href: "#simple-list",
            name: "Требухов"
          },
          {
            href: "#simple-list",
            name: "Украинка"
          },
          {
            href: "#simple-list",
            name: "Ходосовка"
          },
          {
            href: "#simple-list",
            name: "Хотов"
          },
          {
            href: "#simple-list",
            name: "Чабаны"
          },
          {
            href: "#simple-list",
            name: "Чайки"
          },
          {
            href: "#simple-list",
            name: "Ясногородка"
          }
        ]
      },
      typeOfAccommodation: {
        formLabel: "Тип проживания",
        formControlLabelCottage: "Коттеджи",
        formControlLabelBungalow: "Бунгало",
        formControlLabelMainBuilding: "Главное здание",
        formControlLabelVilla: "Вилла",
        formControlLabelTent: "Шатер"
      },
      destinationSlidder: {
        formLabel: "Расстояние от города",
        formLabelKm: "км"
      },
      facilitiesHotels: {
        formLabel: "Удобства в отеле",
        formControlLabelWiFi: "Wi-Fi на территории",
        formControlLabelPool: "Бассейн",
        formControlLabelGym: "Спортзал",
        formControlLabelFish: "Рыбалка",
        formControlLabelHorse: "Прогулки на лошадях",
        formControlLabelSpa: "SPA зона"
      },
      facilitiesRoom: {
        formLabel: "Удобства в номере",
        formControlLabelWiFi: "Wi-Fi в номере",
        formControlLabelAir: "Кондиционер",
        formControlLabelFridge: "Холодильник",
        formControlLabelTv: "Телевизор",
        formControlLabelSafe: "Сейф"
      }
    },
    priceFilter: {
      name: "Цена",
      anyPrice: "Любая цена",
      underPrice: "Меньше",
      currency: "UAH"
    }
  },
  Sorting: {
    byPopularity: "По популярности",
    cheapFirst: "Сначала дешёвые",
    expensiveFirst: "Сначала дорогие",
    byReviewRank: "По оценке жильцов",
    discountFirst: "Сначала со скидками"
  }
};
