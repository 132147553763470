import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Drawer } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  PriceDesktop,
  PriceMobile,
  FilterServiceDesktop,
  FilterServiceMobile
} from "./parts";

import {
  getAllFilters,
  getPriceFilter,
  getPriceMaxFilter,
  getPriceMinFilter,
  getLanguage
} from "../../_reducers/selectors";
import {
  changeAdditionalFilters,
  deleteAdditionalFilters,
  changeFilters,
  deleteFilter
} from "../../_reducers/ducks-filters";
import translate from "../../HOC/translate";
import styles from "../../_jss/MainPage/mainPageStyle";

const useStyles = makeStyles(styles);

function checkIfFilterForServiceTurnOn(service) {
  return (
    service.items.filter(item => {
      return item.isFilter === true;
    }).length > 0
  );
}

const Filters = props => {
  const {
    strings,
    filters,
    price,
    priceMin,
    priceMax,
    changeAdditionalFilters,
    deleteAdditionalFilters,
    changeFilters,
    deleteFilter,
    openDrawer,
    handleClose,
    stateShowMap
  } = props;
  const classes = useStyles();

  const handleChangeCommittedFilterSlider = name => (event, newValue) => {
    event.preventDefault();
    changeAdditionalFilters(name, newValue);
  };

  const handleResetAdditionalFilter = name => e => {
    e.preventDefault();
    deleteAdditionalFilters(name);
  };

  const handleDeleteFilter = (serviceId, nameOfFilter) => e => {
    e.preventDefault();
    deleteFilter(serviceId, nameOfFilter);
  };

  const handleChangeFilterService = (serviceId, itemId) => () => {
    changeFilters(serviceId, itemId);
  };

  const handleTurOnAllService = () => {};

  return (
    <>
      <div
        className={
          stateShowMap ? classes.filterDesktopMap : classes.filterDesktop
        }
      >
        <PriceDesktop
          handleChangeCommittedFilterSlider={handleChangeCommittedFilterSlider}
          handleResetAdditionalFilter={handleResetAdditionalFilter}
          price={price}
          priceMin={priceMin}
          priceMax={priceMax}
          priceTranslate={strings.priceFilter}
        />
        {filters.map(
          (filter, id) =>
            checkIfFilterForServiceTurnOn(filter) && (
              <FilterServiceDesktop
                key={id}
                items={filter.items}
                serviceId={id}
                handleChangeFilter={handleChangeFilterService}
                handleTurnOnAll={handleTurOnAllService}
                handleDeleteFilter={handleDeleteFilter}
                serviceName={strings.servicesName[id]}
                itemsTranslate={strings.services[id]}
                nameOfFilter={filter.name}
              />
            )
        )}
      </div>
      <div className={classes.filterMobile}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.filterOfServ}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                onClick={handleClose}
                color="primary"
                aria-label="Close"
              >
                <Close />
              </IconButton>
            </div>
            <PriceMobile
              handleChangeCommittedFilterSlider={
                handleChangeCommittedFilterSlider
              }
              price={price}
              priceMin={priceMin}
              priceMax={priceMax}
              priceTranslate={strings.priceFilter}
            />
            <FilterServiceMobile
              strings={strings}
              handleChange={handleChangeFilterService}
              filters={filters}
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};

Filters.propTypes = {
  price: PropTypes.number.isRequired,
  priceMin: PropTypes.number.isRequired,
  priceMax: PropTypes.number.isRequired,
  changeAdditionalFilters: PropTypes.func.isRequired,
  deleteAdditionalFilters: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired,
  changeFilters: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  stateShowMap: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  price: getPriceFilter(state),
  priceMin: getPriceMinFilter(state),
  priceMax: getPriceMaxFilter(state),
  currentLanguage: getLanguage(state),
  filters: getAllFilters(state)
});

const mapDispatchToProps = {
  changeAdditionalFilters,
  deleteAdditionalFilters,
  deleteFilter,
  changeFilters
};

const FiltersCompose = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate("Search")
)(Filters);

export { FiltersCompose as Filters };
