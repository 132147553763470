//340px
import React from "react";
import PropTypes from "prop-types";
import { FormLabel, Typography, Tooltip, Slider } from "@material-ui/core/";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "../../../_jss/Filters/filtersStyle";

const useStyles = makeStyles(styles);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#039be5",
    boxShadow: theme.shadows[1],
    fontSize: "0.875rem"
  }
}))(Tooltip);

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });
  return (
    <LightTooltip
      PopperProps={{
        popperRef
      }}
      open={open}
      enterTouchDelay={1}
      placement="top"
      title={`${value} UAH`}
    >
      {children}
    </LightTooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

export const PriceMobile = props => {
  //price: {minPrice: 4500, maxPrice: 220000}},
  const {
    price,
    priceMin,
    priceMax,
    handleChangeCommittedFilterSlider,
    priceTranslate
  } = props;
  const classes = useStyles();

  let step = Math.round((priceMax - priceMin) / 10);
  if (step > 10000) step = Math.round(step / 10);

  return (
    <>
      <FormLabel component="legend" className={classes.forLegend}>
        {priceTranslate.name}
      </FormLabel>
      {price === priceMax ? (
        <Typography variant="body1" style={{ padding: "8px" }}>
          {priceTranslate.anyPrice}
        </Typography>
      ) : (
        <Typography variant="body1" style={{ padding: "8px" }}>
          {priceTranslate.underPrice} {price} {priceTranslate.currency}
        </Typography>
      )}
      <div style={{ margin: "0 16px" }}>
        <Slider
          defaultValue={price}
          onChangeCommitted={handleChangeCommittedFilterSlider("price")}
          aria-label="Custom thumb label"
          ValueLabelComponent={ValueLabelComponent}
          step={step || 500}
          min={priceMin}
          max={priceMax}
          classes={{ thumb: classes.thumb }}
        />
      </div>
    </>
  );
};

PriceMobile.propTypes = {
  price: PropTypes.number.isRequired,
  priceMin: PropTypes.number.isRequired,
  priceMax: PropTypes.number.isRequired,
  handleChangeCommittedFilterSlider: PropTypes.func.isRequired,
  priceTranslate: PropTypes.object.isRequired
};
