import {createMuiTheme } from '@material-ui/core/styles';

export const myTheme = createMuiTheme({
    palette: {
      primary: { main: '#039be5' },
      secondary: { main: '#039be5' },
      default : { main: '#f5f5f5'}
    },
    typography: { useNextVariants: true },
    lowerCase : {textTransform: 'none'}
  });

