import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CardHeader } from "@material-ui/core";
import Rating from "material-ui-rating";

import styles from "../../_jss/MapBox/cardHeaderStyle";

const useStyles = makeStyles(styles);

export const CardHeaderHotelName = props => {
  const { hotelname, rating, currentLanguage } = props;
  const classes = useStyles();
  const { iconButton, icon } = classes;
  const classesRate = { iconButton, icon };
  return (
    <CardHeader
      classes={{
        title: classes.title,
        avatar: classes.avatarRoot,
        root: classes.cardHeaderRoot
      }}
      avatar={<div className={classes.avatarCardHeader}>{rating}</div>}
      title={hotelname[currentLanguage]}
      subheader={
        <Rating readOnly classes={classesRate} value={rating} max={5} />
      }
    />
  );
};

CardHeaderHotelName.propTypes = {
  rating: PropTypes.number,
  hotelname: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired
};
