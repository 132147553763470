import { hotelsApi } from "../api/hotels";
import { prepareFilters } from "./ducks-filters";
//constants
const GET_HOTELS_REQUEST = "zamistom.kiev.ua/hotels/GET_HOTELS_REQUEST";
const GET_HOTELS_SUCCESS = "zamistom.kiev.ua/hotels/GET_HOTELS_SUCCESS";
const GET_HOTELS_FAILURE = "zamistom.kiev.ua/hotels/GET_HOTELS_FAILURE";

const request = () => {
  return { type: GET_HOTELS_REQUEST };
};
const success = hotels => {
  return { type: GET_HOTELS_SUCCESS, hotels };
};
const failure = error => {
  return { type: GET_HOTELS_FAILURE, error };
};
//helper -> find min max price for ROOM
const findMinMaxPriceHotel = data => {
  //console.log(data);
  let priceArray = [];
  data.forEach(hotel => {
    hotel.roomstype.forEach(room => {
      priceArray.push(room.price);
    });
  });
  //console.log(priceArray);
  //console.log(Math.min(...priceArray), Math.max(...priceArray));
  return {
    priceMin: Math.min(...priceArray),
    priceMax: Math.max(...priceArray)
  };
};
//end helper

export const getHotels = () => {
  return dispatch => {
    dispatch(request());
    hotelsApi.getHotels().then(
      response => {
        const priceMinMax = findMinMaxPriceHotel(response.data);
        //console.log(priceMinMax);
        dispatch(success(response.data));
        dispatch(prepareFilters(priceMinMax.priceMin, priceMinMax.priceMax));
      },
      error => {
        if (error.response) {
          console.log("error.response.data", error.response.data);
          dispatch(failure(error.response.data.message.toString()));
        } else if (error.request) {
          console.log("error.request", error.request);
          dispatch(failure(error.message.toString()));
        } else {
          console.log("error.message", error.message);
          dispatch(failure(error.message.toString()));
        }
      }
    );
  };
};

const initialState = { hotels: [], loading: false, error: null, loaded: false };

export const getHotelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTELS_REQUEST:
      return { ...state, loading: true, error: null, loaded: false };
    case GET_HOTELS_SUCCESS:
      return {
        ...state,
        hotels: action.hotels,
        loading: false,
        error: null,
        loaded: true
      };
    case GET_HOTELS_FAILURE:
      return { ...state, error: action.error, loading: false, loaded: false };
    default:
      return state;
  }
};
