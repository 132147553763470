import { hotelsApi } from "../api/hotels";
import { history } from "../_helpers/history";
//constants
const FIND_HOTEL_BY_NAME_REQUEST = "zamistom.kiev.ua/hotels/FIND_HOTEL_BY_NAME_REQUEST";
const FIND_HOTEL_BY_NAME_SUCCESS = "zamistom.kiev.ua/hotels/FIND_HOTEL_BY_NAME_SUCCESS";
const FIND_HOTEL_BY_NAME_FAILURE = "zamistom.kiev.ua/hotels/FIND_HOTEL_BY_NAME_FAILURE";

const request = () => {
  return { type: FIND_HOTEL_BY_NAME_REQUEST };
};
const success = hotel => {
  return { type: FIND_HOTEL_BY_NAME_SUCCESS, hotel };
};
const failure = error => {
  return { type: FIND_HOTEL_BY_NAME_FAILURE, error };
};

export const findHotelByName = hotelname => {
  return dispatch => {
    dispatch(request());

    hotelsApi.findHotelbyName(hotelname.replace(/-/g, " ")).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        if (error.response) {
          console.log("error.response.data", error.response.data);
          dispatch(failure(error.response.data.message.toString()));
          history.push("/");
        } else if (error.request) {
          console.log("error.request", error.request);
          dispatch(failure(error.message.toString()));
          history.push("/");
        } else {
          console.log("error.message", error.message);
          dispatch(failure(error.message.toString()));
          history.push("/");
        }
      }
    );
  };
};

const initialState = { hotel: {}, loading: false, error: null, loaded: false };

export const findHotelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_HOTEL_BY_NAME_REQUEST:
      return { ...state, loading: true, error: null, loaded: false };
    case FIND_HOTEL_BY_NAME_SUCCESS:
      return {
        ...state,
        hotel: action.hotel,
        loading: false,
        error: null,
        loaded: true
      };
    case FIND_HOTEL_BY_NAME_FAILURE:
      return { ...state, error: action.error, loading: false, loaded: false };
    default:
      return state;
  }
};
