import React from "react";
import PropTypes from "prop-types";

import Rating from "material-ui-rating";
import {
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
  Typography
} from "@material-ui/core/";
import { Phone, Place, Web } from "@material-ui/icons/";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../../_jss/HotelPage/headerStyle";

const useStyles = makeStyles(styles);

function AdressOfHotelMulti(props) {
  const { hotel, currentLanguage } = props;
  switch (currentLanguage) {
    case "en":
      return hotel.city[0].value + ", " + hotel.region[0].value;
    case "uk":
      return hotel.city[1].value + ", " + hotel.region[1].value;
    case "ru":
      return hotel.city[2].value + ", " + hotel.region[2].value;
    default:
      return hotel.city[0].value + ", " + hotel.region[0].value;
  }
}

const Header = props => {
  const { hotel, currentLanguage } = props;
  const classes = useStyles();
  // eslint-disable-next-line react/jsx-key
  const iconArray = [<Place />, <Phone />, <Web />];
  const elementList = [
    // eslint-disable-next-line react/jsx-key
    <AdressOfHotelMulti hotel={hotel} currentLanguage={currentLanguage} />,
    hotel.phone,
    hotel.site
  ];

  return (
    <>
      <div style={{ paddingTop: "8px" }}>
        <div className={classes.avatarCardHeader}>{hotel.rating}</div>
        <Typography
          className={classes.displyaInlineBlock}
          variant="h4"
          component="h4"
          align="left"
          color="inherit"
        >
          {hotel.hotelname[currentLanguage]}
        </Typography>
        <Rating
          readOnly
          classes={{
            root: classes.displyaInlineBlock,
            iconButton: classes.iconButton
          }}
          value={hotel.rating}
          max={5}
        />
      </div>

      <List classes={{ root: classes.ListRoot }}>
        {iconArray.map((icon, id) => (
          <div className={classes.displyaInlineBlock} key={id}>
            <ListItem classes={{ root: classes.ListItemRoot }}>
              <ListItemIcon classes={{ root: classes.ListItemIconRoot }}>
                {icon}
              </ListItemIcon>
              <ListItemText
                classes={{ root: classes.ListItemText }}
                primary={elementList[id]}
              />
            </ListItem>
          </div>
        ))}
      </List>
    </>
  );
};

Header.propTypes = {
  hotel: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

export { Header };
